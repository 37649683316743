import { CTAProps } from '@opendoor/bricks/complex/CtaInput';

import useQueryParams from 'helpers/queryParams';

import { useObservability } from '../../helpers/observability';
import { AddressSearchLocation, TrackingTaxonomy } from '../globals';
import { SellerFlowAddressInput, SellerFlowAddressInputProps } from './SellerFlowAddressInput';

export type AddressSearchProp = Pick<CTAProps, 'actionText' | 'actionTextSmall' | 'size'> &
  Pick<
    SellerFlowAddressInputProps,
    | 'channel'
    | 'autoFocus'
    | 'onFocus'
    | 'paddingLevel'
    | 'hideLabel'
    | 'placeholderText'
    | 'labelProps'
  > & {
    trackingTaxonomy: TrackingTaxonomy;
    inputLocation: AddressSearchLocation;
    displayResultsAbove?: boolean;
    analyticsPrefix?: string;
    /**
     * Whether to track an impression event when the input first enters the viewport
     */
    trackInputImpression?: boolean;
    prefilledAddress?: string;
    onSuccess?: SellerFlowAddressInputProps['onSuccess'];
    onChooseManualEntry?: SellerFlowAddressInputProps['onChooseManualEntry'];
    showError?: SellerFlowAddressInputProps['showError'];
    showShadow?: boolean;
    trackingKeyword?: string;
  };

const AddressSearch = ({
  trackingTaxonomy,
  inputLocation,
  autoFocus,
  onFocus,
  paddingLevel,
  actionText,
  actionTextSmall,
  placeholderText,
  size,
  hideLabel,
  displayResultsAbove = false,
  analyticsPrefix = 'cosmos',
  labelProps,
  prefilledAddress,
  channel,
  onSuccess,
  onChooseManualEntry,
  showError,
  showShadow,
  trackingKeyword,
}: AddressSearchProp) => {
  const { trackEvent } = useObservability();

  const { partner_referral } = useQueryParams();

  const onQueryChange = (newQuery?: string, oldQuery?: string) => {
    if (oldQuery === '' && newQuery && newQuery.length > 0) {
      // TODO: remove categoryOverride
      // observability is initialized with 'cosmos' as the category for all events
      trackEvent('typing', trackingTaxonomy, {}, { categoryOverride: 'address-input' });
    }
  };

  return (
    <SellerFlowAddressInput
      analyticsName={`${analyticsPrefix}-address-input`}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus || false}
      inputLocation={inputLocation}
      trackingTaxonomy={trackingTaxonomy}
      onQueryChange={onQueryChange}
      hideLabel={hideLabel}
      designStyle="roundedSquare"
      showShadow={showShadow}
      size={size}
      onFocus={onFocus}
      paddingLevel={paddingLevel}
      displayAbove={displayResultsAbove}
      placeholderText={placeholderText}
      initialInput={prefilledAddress ?? undefined}
      showCta
      showError={showError}
      channel={channel}
      onSuccess={onSuccess}
      onChooseManualEntry={onChooseManualEntry}
      ctaProps={{
        actionText,
        actionTextSmall,
        'aria-label': 'Submit address',
        ...(prefilledAddress !== undefined
          ? {
              prefillBehavior: {
                editCtaText: 'Update address',
                iconProps: {
                  name: 'chevron-right',
                  color: 'brand50',
                  size: 12,
                },
                textProps: {
                  color: 'brand50',
                },
              },
            }
          : {}),
      }}
      product="SELL_DIRECT"
      labelProps={labelProps}
      trackingKeywords={trackingKeyword}
      partnerReferralId={partner_referral as string}
    />
  );
};

export default AddressSearch;
