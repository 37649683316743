import { Box, Flex, Icon, Link, Text } from '@opendoor/bricks/core';

import { EntryComponent } from '../../../cms/entries/entries';
import renderImage from '../../../cms/renderer/assets';
import { IComponentInfoCard } from '../../../declarations/contentful';

const InfoCardComponent = (entry: IComponentInfoCard) => (
  <Box as="section" my={[6, 6, 9, 9]}>
    <Flex
      backgroundColor="blue30"
      gap="6"
      height="100%"
      textAlign="left"
      borderRadius="semiRounded"
      p={[8, 8, 10, 10]}
      flexDir={['column', 'column', 'row']}
      justifyContent={[null, null, 'space-between']}
      alignItems={[null, null, 'center']}
    >
      <Flex flexDirection={['column', 'column', 'row']} alignItems={[null, null, 'center']}>
        {entry?.fields?.icon?.fields?.asset?.fields?.file?.url && (
          <Box
            mr={[null, null, 7, 7]}
            mb={[5, 5, null, null]}
            overflow="hidden"
            width={entry.fields.icon.fields?.asset?.fields?.file?.details?.image?.width || 100}
            height={entry.fields.icon.fields?.asset?.fields?.file?.details?.image?.height || 100}
            position="relative"
          >
            {renderImage({
              src: entry.fields.icon.fields?.asset?.fields?.file?.url,
              width: entry.fields.icon.fields?.asset?.fields?.file?.details?.image?.width || 100,
              height: entry.fields.icon.fields?.asset?.fields?.file?.details?.image?.height || 100,
            })}
          </Box>
        )}
        <Flex flexDir="column">
          {entry?.fields?.title && (
            <Text lineHeight="s2" fontSize="s2" fontWeight="semibold" fontFamily="sans" mb="4">
              {entry?.fields?.title}
            </Text>
          )}
          {entry?.fields?.subtitle && (
            <Text lineHeight={['s0', 's1']} fontSize={['s0', 's1']}>
              {entry?.fields?.subtitle}
            </Text>
          )}
        </Flex>
      </Flex>
      {entry?.fields?.ctaLink && entry?.fields?.ctaText && (
        <Link
          aria-label="Info card link"
          href={entry?.fields?.ctaLink}
          analyticsName="cosmos-topic-page-info-card"
          flexDirection="row"
          display="flex"
          alignItems="center"
        >
          <Text lineHeight={['s0', 's0', 's1']} fontWeight="semibold" color="brand50" fontSize="s1">
            {entry.fields.ctaText}
          </Text>
          <Icon ml="2" name="chevron-right" color="brand50" size={16} position="relative" />
        </Link>
      )}
    </Flex>
  </Box>
);

const InfoCard: EntryComponent<IComponentInfoCard> = {
  render: InfoCardComponent,
};

export default InfoCard;
