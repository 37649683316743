import { Box, Flex, H2, Text } from '@opendoor/bricks/core';
import dynamic from 'next/dynamic';

import { EntryComponent } from '../../cms/entries/entries';
import { ICtaEmailCapture } from '../../declarations/contentful';
import type { EmailInputProps } from '../shared/EmailInput';
import typography from './typography';

const DynamicEmailInput = dynamic<EmailInputProps>(
  () => import('../shared/EmailInput').then((mod) => mod.EmailInput),
  {
    ssr: false,
  },
);

const renderEmailEntry = (entry: ICtaEmailCapture): React.ReactNode => {
  const { fields } = entry;
  return (
    <Box
      borderRadius="roundedSquare"
      mb={[9, null, null, 10]}
      mt={[9, null, null, 10]}
      py={[7, null, null, 8]}
      px={[5, null, null, 8]}
      width={['100%', null, null, '100%']}
      marginRight={[null, null, null, '-10%']}
      backgroundColor="brand50"
    >
      <Flex flexDir="column" alignItems="stretch" boxSizing="border-box" display="inline-flex">
        <H2 {...typography.h2} color="neutrals0" mb={5}>
          {fields.largeTitle}
        </H2>
        <Text {...typography.body} mb={7} color="neutrals0">
          {fields.bodyText}
        </Text>
        <DynamicEmailInput campaignName="articles" ctaText={fields.buttonText} />
      </Flex>
    </Box>
  );
};

const EmailInputComponent: EntryComponent<ICtaEmailCapture> = {
  render: renderEmailEntry,
};

export default EmailInputComponent;
