import { KeyboardEvent, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { Box } from '@opendoor/bricks-next';
import { motion, useScroll, useTransform } from 'framer-motion';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentCtaStandalone } from 'declarations/contentful';

import ContextualField from './ContextualField';
import { LPLink } from './shared/Buttons';
import Sheet from './shared/Sheet';
import {
  BNColorThemes,
  getBNComponentThemeColors,
  getComponentThemeColors,
  IBNComponentThemeConfig,
  IComponentThemeOptions,
} from './shared/styles/ComponentTheme';
import { StackStyle } from './shared/styles/SharedStyles';
import { Desc, Eyebrow, Subhead, TitleWithAccent } from './shared/Typography';

const styles = { ...StackStyle };

const ArchBoxLeft = styled(Box)({
  borderRadius: '0 99em 16em 0',
  fontSize: '100%',
  overflow: 'hidden',
  position: 'relative',
});

const ArchBoxRight = styled(Box)({
  borderRadius: '99em 0 0 16em',
  fontSize: '100%',
  overflow: 'hidden',
  position: 'relative',
});

interface CtaSheetProps {
  entry: ILpComponentCtaStandalone;
  componentTheme: IComponentThemeOptions;
  theme: IBNComponentThemeConfig;
}

const CtaSheet = ({ entry, componentTheme, theme }: CtaSheetProps) => {
  const { fields } = entry;

  const [isVisible, setIsVisible] = useState(false);
  const ctaRef = useRef<HTMLAnchorElement>(null);
  // When animation is complete
  const exitComplete = () => {
    ctaRef.current?.focus();
  };

  // Make visual buttons work like Buttons
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      setIsVisible(true);
    }
  };

  return (
    <>
      {fields.ctaDisplayText && (
        <LPLink
          aria-label={fields.ctaSheetLabel || 'Open dialog'}
          analyticsName={`cosmos-landing-page-cta-standalone-${fields?.analyticsId}`}
          componentTheme={componentTheme}
          bnTheme={theme}
          ctaStyle={fields.ctaStyle}
          ctaDisplayText={fields.ctaDisplayText}
          tabIndex={0}
          onKeyDown={onKeyDown}
          ref={ctaRef}
          onClick={() => setIsVisible(true)}
          mt={7}
        >
          {fields.ctaDisplayText}
        </LPLink>
      )}
      <Sheet
        entry={fields.ctaSheet}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        exitComplete={exitComplete}
      />
    </>
  );
};

const RenderCTAStandalone = (entry: ILpComponentCtaStandalone) => {
  const { fields } = entry;
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref, offset: ['start end', 'end start'] });
  const y = useTransform(scrollYProgress, [0, 1.2], ['8%', '-10%']);
  const componentTheme = getComponentThemeColors(fields.backgroundColor);

  const theme = getBNComponentThemeColors(
    fields.backgroundColor in BNColorThemes
      ? (fields?.backgroundColor as BNColorThemes)
      : BNColorThemes.Light,
  );

  const isWideLayout = fields.layout === 'Wide' || fields.layout === 'Wide Constrained';
  const textAlign = fields.textAlign;

  return (
    <Box className="lp-section">
      <Box
        gap="$8x"
        paddingHorizontal={isWideLayout ? '$10x' : 0}
        flexDirection="row"
        $largerThanMD={{ paddingHorizontal: isWideLayout ? '$20x' : 0 }}
        overflow="hidden"
      >
        {!isWideLayout && (
          <ArchBoxLeft
            aria-hidden={true}
            width="$20x"
            $largerThanSM={{ width: '$44x' }}
            $largerThanMD={{ width: 155 }}
          >
            {fields.leftImage && (
              <motion.img
                src={fields.leftImage.fields?.file?.url}
                alt={fields.leftImage.fields?.description || fields.leftImage.fields?.title}
                ref={ref}
                style={{
                  y,
                  position: 'absolute',
                  top: '-10%',
                  left: '0',
                  right: '0',
                  height: '120%',
                  width: '120%',
                  objectFit: 'cover',
                }}
              />
            )}
          </ArchBoxLeft>
        )}
        <Box
          minHeight={525}
          padding={24}
          borderRadius="$8x"
          flex={1}
          flexBasis={0}
          justifyContent="center"
          alignItems="center"
          marginHorizontal={fields.layout === 'Wide Constrained' ? 'auto' : 0}
          maxWidth={fields.layout === 'Wide Constrained' ? 1100 : undefined}
          width={fields.layout === 'Wide Constrained' ? '100%' : undefined}
          backgroundColor={theme.bgPrimary}
        >
          <Box
            css={styles}
            marginHorizontal={textAlign === 'Left' ? 0 : 'auto'}
            $largerThanSM={{
              maxWidth: 880,
              alignItems: textAlign === 'Left' ? 'flex-start' : 'center',
            }}
            alignItems="flex-start"
          >
            {fields.eyebrow && (
              <Eyebrow
                $largerThanSM={{
                  textAlign: textAlign === 'Left' ? 'left' : 'center',
                }}
                textAlign="left"
                color={theme.contentPrimary}
              >
                {fields.eyebrow}
              </Eyebrow>
            )}
            <TitleWithAccent
              title={fields.title || ''}
              titleSize={fields.titleSize}
              $largerThanSM={{
                textAlign: textAlign === 'Left' ? 'left' : 'center',
              }}
              textAlign="left"
              titleColor={theme.contentPrimary}
              titleAccentColor={theme.contentPrimaryAccent}
            />
            {fields.subhead && (
              <Subhead
                $largerThanSM={{
                  textAlign: textAlign === 'Left' ? 'left' : 'center',
                }}
                textAlign="left"
                color={theme.contentPrimary}
              >
                {fields.subhead}
              </Subhead>
            )}
            {fields.desc && (
              <Desc
                $largerThanSM={{
                  marginHorizontal: textAlign === 'Left' ? 0 : 'auto',
                  textAlign: textAlign === 'Left' ? 'left' : 'center',
                }}
                marginHorizontal={0}
                $largerThanMD={{
                  maxWidth: 400,
                }}
                textAlign="left"
                color={theme.contentPrimary}
              >
                {fields.desc}
              </Desc>
            )}
            {fields.ctaDisplayText && fields.ctaUrl && (
              <Box width="max-content">
                <LPLink
                  aria-label={`Go to page ${fields.ctaDisplayText}`}
                  analyticsName={`cosmos-landing-page-cta-standalone-${fields?.analyticsId}`}
                  bnTheme={theme}
                  ctaStyle={fields.ctaStyle}
                  ctaDisplayText={fields.ctaDisplayText}
                  ctaUrl={fields.ctaUrl}
                  mt={8}
                />
              </Box>
            )}
            {fields.contextualField && (
              <Box
                marginTop={7}
                $largerThanSM={{ marginHorizontal: textAlign === 'Left' ? 0 : 'auto' }}
                marginHorizontal={0}
              >
                <ContextualField
                  theme={componentTheme}
                  contextFields={fields.contextualField}
                  inputLocation="cta-standalone"
                />
              </Box>
            )}
            {fields.ctaDisplayText && fields.ctaSheet && (
              <CtaSheet theme={theme} entry={entry} componentTheme={componentTheme} />
            )}
          </Box>
        </Box>
        {!isWideLayout && (
          <ArchBoxRight
            width="$20x"
            $largerThanSM={{ width: '$44x' }}
            $largerThanMD={{ width: 155 }}
          >
            {fields.rightImage && (
              <motion.img
                src={fields.rightImage.fields?.file?.url}
                alt={fields.rightImage.fields?.description || fields.rightImage.fields?.title}
                ref={ref}
                style={{
                  y,
                  position: 'absolute',
                  top: '-10%',
                  left: '0',
                  right: '0',
                  height: '120%',
                  width: '120%',
                  objectFit: 'cover',
                }}
              />
            )}
          </ArchBoxRight>
        )}
      </Box>
    </Box>
  );
};

const CTAStandalone: EntryComponent<ILpComponentCtaStandalone> = {
  render: RenderCTAStandalone,
};

export default CTAStandalone;
