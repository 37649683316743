import React, { useEffect, useState } from 'react';

import { Box, Icons, Text, TextInputWithToggle } from '@opendoor/bricks-next';

interface FeeInputProps {
  id: string;
  title: string;
  supportText: string;
  defaultValue: number;
  homeSalePrice: number;
  isFormatPercentage?: boolean;
  onValueChange: (id: string, value: number, isPercentage: boolean) => void;
}

const FeeInput: React.FC<FeeInputProps> = React.memo(
  ({
    id,
    title,
    supportText,
    homeSalePrice,
    defaultValue,
    isFormatPercentage = true,
    onValueChange,
  }) => {
    const [fee, setFee] = useState<number>(defaultValue);
    const [isPercentage, setIsPercentage] = useState<boolean>(isFormatPercentage);

    useEffect(() => {
      if (isFormatPercentage) {
        setFee(defaultValue);
      } else {
        setFee((defaultValue / homeSalePrice) * 100);
      }
    }, [defaultValue, homeSalePrice, isFormatPercentage]);

    const handleFeeFormatChange = (isPercentage: boolean) => {
      setIsPercentage(isPercentage);
      setFee((prevFee) => {
        if (isPercentage) {
          return Number(((prevFee / homeSalePrice) * 100).toFixed(2));
        }
        return Number(((prevFee / 100) * homeSalePrice).toFixed(2));
      });
    };

    const validateAndSetFee = (value: string) => {
      const numericValue = Number(value.replace(/,/g, ''));
      if (!isNaN(numericValue)) {
        setFee(numericValue);
        onValueChange(id, numericValue, isPercentage);
      }
      return;
    };

    function getFeesEquivalent(value: number, isPercentage: boolean) {
      return isPercentage
        ? Math.round((homeSalePrice / 100) * value)
            .toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
            .slice(0, -3)
        : `${Math.round((value / homeSalePrice) * 100)}%`;
    }
    return (
      <TextInputWithToggle
        id={id}
        value={fee.toLocaleString('en-US')}
        onChange={(event: any) => {
          validateAndSetFee(event.target.value);
        }}
        size="medium"
        title={title}
        supportText={supportText}
        prefix={isPercentage ? '' : '$'}
        suffix={isPercentage ? '%' : ''}
        toggleProps={{
          leftIcon: { Component: Icons.Dollar, label: 'dollars' },
          rightIcon: { Component: Icons.Percentage, label: 'percent' },
          defaultChecked: isPercentage,
          onCheckedChange: (isPercentage: boolean) => {
            handleFeeFormatChange(isPercentage);
          },
        }}
      >
        <Box justifyContent="center">
          <Text tag="span" typography="$bodyLarge" color="$contentTertiary">
            {getFeesEquivalent(fee, isPercentage)}
          </Text>
        </Box>
      </TextInputWithToggle>
    );
  },
);
FeeInput.displayName = 'FeeInput';
export default FeeInput;
