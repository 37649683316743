import React, { MouseEvent, useRef } from 'react';

import { Box, Flex, Image, Link, Text } from '@opendoor/bricks/core';
import { breakpoints, shadows } from '@opendoor/bricks/theme/novo';

import { generatePresetPictureSet, PresetPictureSet } from '../shared/performance/pictures';

export interface ArticleCardProps {
  imageSourceUrl?: string;
  tags?: Array<string>;
  slug?: string;
  articleName?: string;
  publishDate?: string;
  hideImage?: boolean;
  flexDirection?: 'column' | 'row';
  hideCategory?: boolean;
}

// Article card reused in articles and topic pages
const ArticleCard: React.FC<ArticleCardProps> = ({
  imageSourceUrl,
  tags = [],
  slug,
  articleName,
  publishDate,
  hideImage = false,
  flexDirection = 'column',
  hideCategory = false,
}) => {
  const articleRef = useRef<HTMLAnchorElement>(null);
  const articleCategory = getArticleCategory(tags);
  const isHorizontalOrientation = flexDirection === 'row';

  /**
   * Note: when rendering the product cards, we attempt to
   * use semantic HTML.
   * The overall structure is:
   * <div> (entire gallery)
   *   <article> (ArticleCard)
   *     <a>.../a>
   *   </article>
   *   ...
   * </div>
   * Each card is an <article>.
   *
   * The whole card is clickable, but we use an onClick handler for a11y
   * reasons. (only href focuses rather than the whole card focuses)
   *
   */
  return (
    <Flex
      onClick={() => {
        articleRef?.current?.click();
      }}
      width="100%"
      cursor="pointer"
      flexDirection={['column', 'column', flexDirection, flexDirection]}
      px={0}
      py={0}
      borderRadius="semiRounded"
      overflow="hidden"
      css={{ boxShadow: shadows.small }}
      as="article"
    >
      {/* Cover image */}
      <Box
        overflow="hidden"
        width={[
          'unset',
          'unset',
          isHorizontalOrientation ? '50%' : '100%',
          isHorizontalOrientation ? '50%' : '100%',
        ]}
      >
        {!hideImage &&
          (imageSourceUrl ? (
            generatePresetPictureSet({
              photoUrl: imageSourceUrl,
              sizes: ARTICLE_COVER_IMAGE_SIZE_CONFIG,
              debugImageSizes: false,
              radii: {},
              preload: true,
              lazy: false,
            })
          ) : (
            <Image
              alt="Article cover fallback image"
              src="https://images.opendoor.com/source/s3/imgdrop-production/4b831192b06849a589afcef62a25757d.jpg?preset=square-2048"
              objectPosition="left"
            />
          ))}
      </Box>
      <Flex
        justifyContent={[
          'unset',
          'unset',
          isHorizontalOrientation ? 'center' : 'unset',
          isHorizontalOrientation ? 'center' : 'unset',
        ]}
        width={[
          '100%',
          '100%',
          isHorizontalOrientation ? '50%' : 'unset',
          isHorizontalOrientation ? '50%' : 'unset',
        ]}
        flexDir="column"
        p={[5, 5, 6, 6]}
        gap={[3, 3, 4, 4]}
      >
        {/* Article Tag */}
        {!hideCategory && articleCategory && (
          <Text
            size="s0"
            fontWeight="regular"
            lineHeight="s0"
            fontFamily="sans"
            textColor="neutrals90"
            height="24px"
          >
            {articleCategory}
          </Text>
        )}
        {/* Title */}
        <Link
          aria-label="article card"
          href={slug}
          analyticsName="cosmos-topicpage-articles-lite-card"
          ref={articleRef}
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
          }}
        >
          <Text
            size="s2"
            fontWeight="semibold"
            lineHeight="s2"
            fontFamily="sans"
            textColor="neutrals100"
            overflow="hidden"
            noOfLines={2}
          >
            {articleName}
          </Text>
        </Link>
        {/* Publish date */}
        <Text
          size="s0"
          fontWeight="regular"
          lineHeight="s0"
          fontFamily="sans"
          textColor="neutrals90"
          height="24px"
        >
          {publishDate
            ? new Date(publishDate + 'T00:00:00').toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            : ''}{' '}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ArticleCard;

export const getArticleCategory = (tags: Array<string>) => {
  const filteredTags = tags?.filter((tag) => !['blog', 'guide'].includes(tag));
  return filteredTags.length > 0
    ? filteredTags[0] // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    : '';
};

const ARTICLE_COVER_IMAGE_SIZE_CONFIG: PresetPictureSet = [
  {
    minWidth: breakpoints[0],
    maxWidth: breakpoints[1],
    preset: 'square-256',
  },
  {
    minWidth: breakpoints[1],
    maxWidth: breakpoints[2],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[2],
    maxWidth: breakpoints[3],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[3],
    maxWidth: null,
    preset: 'square-1024',
  },
];
