import { Flex, Link, Text } from '@opendoor/bricks/core';

import { EntryComponent } from '../../cms/entries/entries';
import DynamicTooltip from '../../components/shared/DynamicTooltip';
import { IMetricBasic } from '../../declarations/contentful';

const formatMetric = (metricValue: number, metricFormat: IMetricBasic['fields']['format']) => {
  switch (metricFormat) {
    case 'currency':
      // just take whatever is inputted if it's less than 1000
      if (metricValue < 1000) {
        return `$${metricValue}`;
      }
      return `$${Math.round(metricValue / 1000).toFixed(0)}k`;
    case 'decimal':
      return metricValue.toLocaleString();
    case 'number':
      return metricValue.toLocaleString();
    case 'percent':
      return metricValue.toLocaleString('en-US', { style: 'percent', maximumFractionDigits: 3 });
    default:
      return `${metricValue}`;
  }
};

const formatSource = (source?: string) => {
  if (!source) {
    return 'Opendoor';
  }
  try {
    const url = new URL(source);
    if (!url.protocol.startsWith('http')) {
      return source;
    }
    return (
      <Link
        fontSize="s0"
        href={source}
        analyticsName="cosmos-articles-basic-metric-source"
        aria-label=""
      >
        {url.host}
      </Link>
    );
  } catch {
    return source;
  }
};

const renderBasicMetric = (entry: IMetricBasic) => {
  const fields = entry.fields;
  if (fields.value === undefined) {
    return null;
  }

  let relativeChange: number | null = null;
  if (fields.comparisonValue) {
    // calculate relative to the comparison value, since we want
    // to display the *current* metric with it's relative change
    // x_1	=	initial value
    // x_2	=	final value
    const x1 = fields.comparisonValue;
    const x2 = fields.value;
    relativeChange = (x2 - x1) / x1;
  }
  return (
    <Flex key={fields.metricId || fields.metricName} flexDirection={'column'}>
      <Flex alignItems="center">
        <Text
          mb="0"
          mt="0"
          fontWeight="semibold"
          lineHeight={['s3', null, null, 's4']}
          fontSize={['s4', null, null, 's5']}
          color="brand50"
        >
          {formatMetric(fields.value, fields.format)}
        </Text>
        {relativeChange !== null && (
          <Flex ml="2" flexDirection="column" justifyContent="center">
            <Text
              as="span"
              mb="0"
              lineHeight="s000"
              fontSize={['s0', null, null, 's1']}
              textAlign="left"
            >
              {relativeChange < 0 ? '↓' : '↑'}
            </Text>
            <Text
              as="span"
              size="s0"
              mt="0"
              lineHeight="s000"
              fontSize={['s0', null, null, 's1']}
              textAlign="left"
            >
              {Math.round(relativeChange * 100).toFixed(0)}%
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex alignItems="center">
        <Text mt="0" mb="0">
          {fields.metricName}
        </Text>
        <DynamicTooltip
          label={`Metric info and source information`}
          popupDirection="bottom"
          shade="UNSAFE_light"
          tooltipIcon={<Text fontSize="s00">&#x24D8;</Text>}
          analyticsName="cosmos-articles-metric-info-and-source-tooltip"
        >
          <Text size="s0" mb="0" mt="0">
            Metric: {fields.metricDescription}
          </Text>
          {relativeChange && (
            <Text size="s0" mb="0" mt="0">
              Comparison: {fields.comparisonDescription}
            </Text>
          )}
          <Text size="s0" mb="0" mt="0">
            Source: {formatSource(fields.metricSource)}
          </Text>
        </DynamicTooltip>
      </Flex>
    </Flex>
  );
};

const BasicMetric: EntryComponent<IMetricBasic> = {
  render: renderBasicMetric,
};

export default BasicMetric;
