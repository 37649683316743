/* storybook-check-ignore */
import { Component, memo, PropsWithChildren, ReactNode } from 'react';

import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import {
  Block,
  BLOCKS,
  Text as ContentfulText,
  helpers,
  Inline,
  INLINES,
  Paragraph,
  Document as RichTextDocument,
} from '@contentful/rich-text-types';
import {
  Box,
  H1,
  H2,
  H3,
  H4,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from '@opendoor/bricks/core';
import { globalObservability } from '@opendoor/observability/slim';
import isEqual from 'lodash/isEqual';

import typography from '../../components/articles/typography';
import { COSMOS_URLS } from '../../components/globals';
import { CONTENT_TYPE } from '../../declarations/contentful';
import IEntryLookup, { EntryComponent } from '../entries/entries';
import { IIdToLoaderData } from '../loaders';
import renderImage, { isAsset } from './assets';

const Sentry = globalObservability.getSentryClient();

class EntryErrorBoundary extends Component<
  PropsWithChildren<{ entry: unknown; loaderData: unknown }>,
  { hasError: boolean }
> {
  constructor(props: { entry: unknown; loaderData: unknown }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    Sentry.captureException?.(error, {
      captureContext: {
        extra: {
          errorInfo,
          entry: this.props.entry,
          loaderData: this.props.loaderData,
        },
      },
    });
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

const scrollAnchorStyle = {
  scrollMarginTop: '80px',
};

type IRendererConstructor = Pick<RichTextRenderer, 'body' | 'idToLoaderData' | 'entryLookup'>;

const RendererComponent = memo(
  (props: IRendererConstructor) => {
    return new RichTextRenderer(props).render();
  },
  (prev, next) => {
    return Object.entries(prev).every(([k, v]) => {
      return isEqual(v, next[k as keyof typeof prev]);
    });
  },
);
RendererComponent.displayName = 'RichTextRenderer';

const isAllText = (
  content: Array<Block | Inline | ContentfulText>,
): content is Array<ContentfulText> => {
  return content.every((c) => helpers.isText(c));
};

class RichTextRenderer {
  body: RichTextDocument;
  idToLoaderData: IIdToLoaderData;
  entryLookup: IEntryLookup;
  options: Options;
  generatedIds: { [key: string]: number };

  constructor({ body, idToLoaderData, entryLookup }: IRendererConstructor) {
    this.body = body;
    this.idToLoaderData = idToLoaderData;
    this.entryLookup = entryLookup;
    this.options = this.getOption();
    this.generatedIds = {};
  }

  generateId(content: Array<Block | Inline | ContentfulText>): string | null {
    if (!isAllText(content)) {
      return null;
    }
    // Type hint, in the "skipId" section, we verified there
    // are only
    const input = content.reduce((acc, curr) => {
      return acc + curr.value;
    }, '');
    let output = '';
    for (let i = 0; i < input.length; i++) {
      const curr = input[i];
      // replace any non word charager with an empty space
      // skip dashes with this
      if (curr === '-') {
        output = output + '-';
        continue;
      }
      // whitespace
      if (curr.match(/\s/)) {
        output = output + '-';
        continue;
      }
      if (curr.match(/\W/)) {
        continue;
      }
      output = output + curr.toLowerCase();
    }
    let id = output.replaceAll(/-+/g, '-');
    // handle duplicates - we'll just increase the appended
    // index if we have a duplicate id
    if (id in this.generatedIds) {
      this.generatedIds[id] += 1;
      id = `${id}-${this.generatedIds[id]}`;
    } else {
      this.generatedIds[id] = 0;
    }
    return id;
  }

  getOption(): Options {
    return {
      renderNode: {
        [BLOCKS.HEADING_1]: (node, children) => {
          const id = this.generateId(node.content);
          return (
            <H1 id={id ?? ''} marginTop={2} mb={2} color="neutrals100" css={scrollAnchorStyle}>
              {children}
            </H1>
          );
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          const id = this.generateId(node.content);
          return (
            <H2
              id={id ?? ''}
              mt={7}
              mb={5}
              {...typography.h2}
              color="neutrals100"
              css={scrollAnchorStyle}
            >
              {children}
            </H2>
          );
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          const id = this.generateId(node.content);
          return (
            <H3
              id={id ?? ''}
              mt={6}
              mb={4}
              {...typography.h3}
              color="neutrals100"
              css={scrollAnchorStyle}
            >
              {children}
            </H3>
          );
        },
        [BLOCKS.QUOTE]: (_, children) => {
          return (
            <Box
              borderRadius="roundedSquare"
              py={[4, null, null, 6]}
              px={[4, null, null, 7]}
              width="100%"
              backgroundColor="neutrals20"
            >
              {children}
            </Box>
          );
        },
        [BLOCKS.EMBEDDED_ENTRY]: (entryData) => {
          const entryComponent =
            this.entryLookup.block[
              entryData.data.target?.sys?.contentType?.sys?.id as CONTENT_TYPE
            ];
          if (!entryComponent) {
            return null;
          }
          return this.renderEntry(entryComponent, entryData);
        },
        [BLOCKS.EMBEDDED_ASSET]: (entryData) => {
          const fields = entryData.data.target.fields;
          if (!isAsset(fields)) {
            return null;
          }
          return renderImage({
            src: fields.file?.url,
            description: fields.description,
            width: fields.file.details.image.width,
            height: fields.file.details.image.height,
          });
        },
        [INLINES.EMBEDDED_ENTRY]: (entryData) => {
          const entryComponent =
            this.entryLookup.inline[
              entryData.data.target?.sys?.contentType?.sys?.id as CONTENT_TYPE
            ];
          if (!entryComponent) {
            return null;
          }
          return this.renderEntry(entryComponent, entryData);
        },
        [BLOCKS.HEADING_4]: (node, children) => {
          const id = this.generateId(node.content);
          return (
            <H4 id={id ?? ''} {...typography.h4} css={scrollAnchorStyle}>
              {children}
            </H4>
          );
        },
        [BLOCKS.PARAGRAPH]: (entry: Block | Inline, children) => {
          const paragraphEntryContent = entry.content as Paragraph['content'];
          if (!Array.isArray(children)) {
            return (
              <Text mb={3} {...typography.body}>
                {children}
              </Text>
            );
          }

          // Don't render empty paragraph where the node only contains whitespace text
          const isEmptyParagraph =
            paragraphEntryContent.length === 1 &&
            paragraphEntryContent[0].nodeType == 'text' &&
            typeof paragraphEntryContent[0].value === 'string' &&
            paragraphEntryContent[0].value.trim().length === 0;
          if (isEmptyParagraph) {
            return null;
          }

          const zipped: Array<[Inline | ContentfulText, ReactNode]> = paragraphEntryContent.map(
            (e, i) => [e, children[i]],
          );
          const { inline, standard } = zipped.reduce<{
            inline: Array<ReactNode>;
            standard: Array<ReactNode>;
          }>(
            (acc, curr) => {
              const [entry, child] = curr;
              if (entry.nodeType === INLINES.EMBEDDED_ENTRY) {
                acc.inline = acc.inline.concat(child);
                return acc;
              }
              acc.standard = acc.standard.concat(child);
              return acc;
            },
            { inline: [], standard: [] },
          );
          // note: this re-orders the text components, i.e. if you have
          // test <inline entry> test2, it will be rendered as
          // <inline entry> test test2. This doesn't seem ideal, but works
          // for our use-case. We may want to disallow inline embedded entries
          // in the future, and just use the block type.
          return (
            <>
              {inline}
              <Text my={6} {...typography.body}>
                {standard}
              </Text>
            </>
          );
        },
        [INLINES.HYPERLINK]: (node, children) => {
          let target = '_blank';
          if (
            // https://opendoor.atlassian.net/jira/servicedesk/projects/ENGBUGS/queues/custom/1337/ENGBUGS-47459
            // we want all articles referenced in our articles to open in a new tab
            // we only add production urls to contentful
            !node.data.uri.startsWith(COSMOS_URLS['production'] + '/articles') &&
            (node.data.uri.startsWith('#') || node.data.uri.startsWith(COSMOS_URLS['production']))
          ) {
            target = '_self';
          }
          return (
            <Link
              href={node.data.uri}
              {...typography.body}
              analyticsName="cosmos-articles-renderer"
              aria-label=""
              target={target}
            >
              {children}
            </Link>
          );
        },
        [BLOCKS.TABLE]: (_, children) => {
          return (
            <Table>
              <TableBody>{children}</TableBody>
            </Table>
          );
        },
        [BLOCKS.TABLE_CELL]: (_, children) => {
          return (
            <TableCell pr={[4, 4, 5, 6]} textAlign="left">
              {children}
            </TableCell>
          );
        },
        [BLOCKS.TABLE_ROW]: (_, children) => {
          return <TableRow>{children}</TableRow>;
        },
        [BLOCKS.TABLE_HEADER_CELL]: (_, children) => {
          return (
            <TableHeader pr={[4, 4, 5, 6]} textAlign="left">
              {children}
            </TableHeader>
          );
        },
      },
    };
  }

  /**
   * Render an entry safely, with Error Boundaries and safe server-side try-catch
   */
  renderEntry(entryComponent: EntryComponent, entryData: Block | Inline) {
    const loaderData = this.idToLoaderData[entryData.data.target.sys.id];
    const { body, idToLoaderData } = this;
    let component: ReactNode | undefined;
    /**
     * Render the entry safely server-side
     */
    try {
      component = entryComponent.render(entryData.data.target, loaderData, {
        body,
        idToLoaderData,
      });
    } catch (e) {
      globalObservability.getSentryClient().captureException?.(e, {
        data: {
          target: entryData.data.target,
          loaderData,
        },
      });
      return null;
    }
    return (
      <EntryErrorBoundary entry={entryData.data.target} loaderData={loaderData}>
        {component}
      </EntryErrorBoundary>
    );
  }

  /**
   * Custom memoized rich text renderer, only re-render it at a top-level if the body
   * input has changed (deep diff). Prevents re-renders if something at the top level
   * is misbehaving.
   */
  render() {
    return <>{documentToReactComponents(this.body, this.options)}</>;
  }

  static Component = RendererComponent;
}

export default RichTextRenderer;
