import LazyLoad from 'react-lazyload';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@opendoor/bricks-next';
import { Box, Flex, Image } from '@opendoor/bricks/core';
import { breakpoints } from '@opendoor/bricks/theme/novo';
import dynamic from 'next/dynamic';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentHeroCard } from 'declarations/contentful';

import { generatePresetPictureSet, PresetPictureSet } from '../shared/performance/pictures';
import Grid, { gridStyles } from './Grid';

const Parallax = dynamic(() => import('react-scroll-parallax').then((mod) => mod.Parallax));

const Container = styled(Flex)(
  (props: { isVideoAsset: boolean }) => `
  @media (min-width: 375px) and (max-width: 460px) {
    max-height: ${props.isVideoAsset ? '560px' : '440px'};
  }
  @media (min-width: 461px) and (max-width: 550px) {
    max-height: ${props.isVideoAsset ? '596px' : '490px'};
  }
  @media (min-width: 551px) and (max-width: 615px) {
    max-height: ${props.isVideoAsset ? '596px' : '510px'};
  }
  @media (min-width: 616px) and (max-width: 767px) {
    max-height: ${props.isVideoAsset ? '596px' : '520px'};
  }
  @media (min-width: 768px) and (max-width: 865px) {
    min-height: 396px;
  }
  @media (min-width: 865px) and (max-width: 992px) {
    min-height: 472px;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    min-height: 580px;
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    min-height: 630px;
  }
  @media (min-width: 1440px) {
    min-height: 680px;
  }
`,
);

const PICTURE_SIZES: PresetPictureSet = [
  {
    minWidth: breakpoints[0],
    maxWidth: breakpoints[1],
    preset: 'square-256',
  },
  {
    minWidth: breakpoints[1],
    maxWidth: breakpoints[2],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[2],
    maxWidth: breakpoints[3],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[3],
    maxWidth: null,
    preset: 'square-1024',
  },
];

const ParallaxBallons = () => {
  return (
    <Parallax speed={20}>
      <Image
        alt="parallax effect with balloons"
        src="https://images.opendoor.com/source/s3/imgdrop-production/2023-07-1689881604806-95436.png?preset=square-2048"
        loading="lazy"
        objectFit="contain"
        width="100%"
        height="auto"
      />
    </Parallax>
  );
};

const ParallaxVideoWalkthrough = () => {
  return (
    <Parallax speed={10}>
      <Image
        alt="parallax effect with video walkthrough desktop"
        src="https://images.opendoor.com/source/s3/imgdrop-production/2023-07-1689882596406-54963.jpeg?preset=square-2048"
        loading="lazy"
        objectFit="contain"
        width="100%"
        height="auto"
      />
    </Parallax>
  );
};

const MobileSpacing = 9;
const LargeSpacing = 11;
const ExtraLargeSpacing = '80px';

const RenderHeroCard = (entry: ILpComponentHeroCard) => {
  const { fields } = entry;
  const isVideoAsset = fields?.clipImage && fields?.videoUrl;

  return (
    <Box analyticsName={`cosmos-landing-page-hero-card-${fields?.analyticsId}`} trackImpression>
      <LazyLoad height="100%" once offset={150}>
        <Grid>
          <Container
            gridColumn={gridStyles.colSpan12}
            style={{
              backgroundColor: '#F6F5F4',
              borderRadius: '36px',
            }}
            mb={[6, null, 7]}
            justifyContent={['space-between', null, 'space-between']}
            pl={[0, null, LargeSpacing, ExtraLargeSpacing]}
            gap={[0, null, 8]}
            height={['472px', '596px', '396px', '592px']}
            flexDirection={['column', null, 'row']}
            alignItems={['center', null, 'flex-start']}
            overflow="hidden"
            isVideoAsset={!!isVideoAsset}
          >
            <Flex
              pt={[MobileSpacing, null, LargeSpacing, ExtraLargeSpacing]}
              px={[MobileSpacing, null, 'unset', 0]}
              pb={[MobileSpacing, null, LargeSpacing, ExtraLargeSpacing]}
              flexDir="column"
              justifyContent="space-between"
              width="100%"
              height={[null, null, '100%']}
              maxWidth={[null, null, '236px', '380px']}
            >
              <Box>
                <Text
                  tag="h3"
                  display="block"
                  typography="$bodyLarge"
                  fontWeight="$medium"
                  $largerThanMD={{ typography: '$subheaderMedium' }}
                  color={'$contentPrimary'}
                  mb={10}
                >
                  {fields.heroCardName}
                </Text>
              </Box>
              <Box display="block" width={['100%', null, '100%']}>
                <Text
                  tag="h2"
                  display="block"
                  typography="$subheaderMedium"
                  $largerThanSM={{ typography: '$headerSmall' }}
                  $largerThanMD={{ typography: '$headerLarge' }}
                >
                  {fields?.title + ' '}
                  {fields?.lineBreakOnMobile && (
                    <Box display={['block', null, 'none']} height="0">
                      <br />
                    </Box>
                  )}
                  <Text
                    tag="span"
                    typography="$subheaderMedium"
                    $largerThanSM={{ typography: '$headerSmall' }}
                    $largerThanMD={{ typography: '$headerLarge' }}
                    color="$contentTertiary"
                  >
                    {fields?.secondaryTitle}
                  </Text>
                </Text>
              </Box>
            </Flex>
            <Box width="100%" height="109%" minHeight="300px" position="relative">
              {isVideoAsset ? (
                <Flex
                  height="100%"
                  width="100%"
                  justifyContent={['center', null, 'flex-end']}
                  alignItems="flex-end"
                  pr={[null, null, '70px', '80px']}
                  pt={[null, null, 10]}
                  css={css`
                    @media (min-width: 950px) and (max-width: 992px) {
                      padding-right: 100px;
                    }
                  `}
                >
                  <Flex
                    width={['100%', null, '80%']}
                    justifyContent={['center', null, 'flex-end']}
                    minWidth={[null, null, '286px', '495px']}
                    maxHeight={[null, '420px', 'unset']}
                    height="100%"
                    // @ts-expect-error  design isn't supported by token<radii>
                    borderRadius={'400.696px 400.696px 0px 0px'}
                    overflow="hidden"
                  >
                    <video
                      height="100%"
                      width="100%"
                      src={fields?.videoUrl}
                      autoPlay
                      muted
                      loop
                      playsInline
                      style={{
                        objectFit: 'cover',
                      }}
                    >
                      {/* Fallback image */}
                      {fields?.cardImage && (
                        <Image
                          height="100%"
                          bottom="0"
                          objectFit="cover"
                          right="0"
                          alt=""
                          src={fields?.cardImage}
                          loading="lazy"
                        />
                      )}
                    </video>
                  </Flex>
                </Flex>
              ) : fields?.cardImage ? (
                <Box>
                  <Box
                    position="absolute"
                    width="100%"
                    height="100%"
                    left="0"
                    display="block"
                    bottom="0"
                    objectFit="cover"
                    objectPosition="50% 0%"
                    margin={0}
                    pt={[null, null, 10]}
                    css={css`
                      @media (min-width: 992px) and (max-width: 1200px) {
                        transform: translate(7%, 0px);
                      }
                      @media (min-width: 1201px) and (max-width: 1440px) {
                        transform: translate(5%, 0);
                      }
                      @media (min-width: 1440px) {
                        transform: translate(9%, 0);
                      }
                    `}
                  >
                    {generatePresetPictureSet({
                      photoUrl: fields?.cardImage,
                      sizes: PICTURE_SIZES,
                      debugImageSizes: false,
                      radii: {},
                      preload: true,
                      lazy: false,
                      objectFit: ['cover', 'contain', 'cover'],
                    })}
                  </Box>
                  {fields.parallaxType === 'balloons' && <ParallaxBallons />}
                  {fields.parallaxType === 'video_walkthrough' && <ParallaxVideoWalkthrough />}
                </Box>
              ) : null}
            </Box>
          </Container>
        </Grid>
      </LazyLoad>
    </Box>
  );
};

const HeroCard: EntryComponent<ILpComponentHeroCard> = {
  render: RenderHeroCard,
};

export default HeroCard;
