import styled from '@emotion/styled';
import { Text } from '@opendoor/bricks/core';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentQuotes } from '../../declarations/contentful';

const Blockquote = styled.blockquote`
  position: relative;
  margin: 50px 0;
  padding-left: 60px;

  &:before {
    content: '';
    position: absolute;
    top: 13px;
    left: 0;
    height: 20px;
    width: 30px;
    background: url('https://images.opendoor.com/source/s3/imgdrop-production/267a8026eb7b4dbca5bb6c17eb58c328.svg?preset=square-2048')
      left center no-repeat;
  }
`;

const renderQuote = (entry: IComponentQuotes) => {
  const { fields } = entry;
  return (
    <Blockquote>
      <Text
        fontWeight="bold"
        lineHeight="s5"
        color="neutrals100"
        fontSize={['s3', null, null, 's4']}
      >
        {fields.content}
      </Text>
    </Blockquote>
  );
};

const Quote: EntryComponent<IComponentQuotes> = {
  render: renderQuote,
};

export default Quote;
