import { Box, Card, Flex, Link, Text } from '@opendoor/bricks/core';
import startCase from 'lodash/startCase';
import take from 'lodash/take';
import toLower from 'lodash/toLower';

import { Awaited, EntryComponent } from '../../cms/entries/entries';
import { EXCLUSIVE_RAILS_HOST } from '../../components/globals';
import { IComponentExclusiveListings } from '../../declarations/contentful';
import { ListingWithComputedProperties } from '../../declarations/exclusives/listing';
import {
  getListingThumbnail,
  getListingWithComputedProperties,
} from '../../helpers/exclusives/listing';
import GalleryCard from '../exclusives/gallery/GalleryCard';

export type ExclusiveListingsReturn = Awaited<ReturnType<typeof exclusivesGalleryLoader>>;

const NUM_EXCLUSIVE_CARDS_TO_SHOW = 10;

const exclusivesGalleryLoader = async (
  input: IComponentExclusiveListings,
): Promise<Array<ListingWithComputedProperties>> => {
  const market = input.fields.market;
  const response = await fetch(
    `${EXCLUSIVE_RAILS_HOST}/api/v1/exclusive_listings/search?${market ? `?market=${market}` : ''}`,
  );
  const body: ListingWithComputedProperties[] = (await response.json()).map(
    getListingWithComputedProperties,
  );
  return take(body, NUM_EXCLUSIVE_CARDS_TO_SHOW);
};

// TODO: Maybe add a header and footer.
const renderExclusivesGallery = (
  entry: IComponentExclusiveListings,
  resolvedData?: ExclusiveListingsReturn,
) => {
  return (
    <Box>
      <Text size="s4">{entry.fields.title}</Text>
      <Box width={['100%', null, null, '100%']} marginRight={[null, null, null, '-10%']}>
        <ul
          style={{
            listStyle: 'none',
            marginLeft: '0',
            paddingLeft: '0',
            paddingTop: '10px',
            paddingBottom: '5px',
            marginBottom: '10px',
          }}
        >
          <Flex
            position="relative"
            flexWrap={['nowrap', null, null, 'wrap']}
            gridGap={'9'}
            overflow="auto"
            paddingBottom={'4'}
            paddingLeft={['4', null, null, '0']}
          >
            {resolvedData?.map((listing) => (
              <Box
                key={listing.id}
                id={`home-${listing.id}`}
                style={{ scrollMarginTop: '80px' }}
                maxWidth="400px"
              >
                <Link
                  aria-label={null}
                  href={`/exclusives/homes/${encodeURIComponent(listing.slug)}`}
                  analyticsName={`cosmos-exclusives-home-${listing.id}`}
                >
                  <GalleryCard
                    listing={listing}
                    exclusivesBadgeColorOverride="#082DE6"
                    url={getListingThumbnail(listing, '_768x512')}
                  />
                </Link>
              </Box>
            ))}
            <Card
              id="see-more-exclusives-card"
              aria-label={`See more Exclusive listings in ${entry.fields.market}`}
              analyticsName={`cosmos-articles-exclusive-listings-more-${entry.fields.market}`}
              onClick={() => window.open(`/exclusives/gallery/${entry.fields.market}`)}
              maxWidth="400px"
              variant="secondary"
              bg="#082DE6"
              flex={1}
              justifyContent="center"
              textAlign="center"
              _hover={{ bg: '#6985FF' }}
            >
              <Text color="neutrals0" fontWeight="semibold">
                More Opendoor Exclusives in {startCase(toLower(entry.fields.market))} &gt;&gt;&gt;
              </Text>
            </Card>
          </Flex>
        </ul>
      </Box>
    </Box>
  );
};

const ExclusivesGallery: EntryComponent<IComponentExclusiveListings, ExclusiveListingsReturn> = {
  render: renderExclusivesGallery,
  loader: exclusivesGalleryLoader,
};

export default ExclusivesGallery;
