// storybook-check-ignore
import styled from '@emotion/styled';
import { Box, Text } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronDown from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronDown16';
import 'swiper/css';
import 'swiper/css/pagination';

import articleEntryLookup from 'cms/entries/article';
import { Awaited, EntryComponent, IRenderContext } from 'cms/entries/entries';
import { IIdToLoaderData } from 'cms/loaders';

import RichTextRenderer from 'components/articles-v2/renderer';
import Container from 'components/landing-pages-v2/shared/Container';
import {
  getComponentThemeColors,
  IComponentThemeOptions,
} from 'components/landing-pages-v2/shared/styles/ComponentTheme';
import { Gutter, StackStyle } from 'components/landing-pages-v2/shared/styles/SharedStyles';
import {
  Desc,
  Eyebrow,
  Subhead,
  SubheadSmall,
  TitleWithAccent,
} from 'components/landing-pages-v2/shared/Typography';
import { Accordion } from 'components/shared/Accordion';
import CardCarouselV2 from 'components/shared/CardCarouselV2';
import { CardImage } from 'components/shared/Multicard/MulticardCard';

import { IComponentMulticard, IComponentMulticardCard } from 'declarations/contentful';

const columns = {
  Two: 2,
  Three: 3,
  Four: 4,
};

interface IComponentMulticardCardProps {
  card: IComponentMulticardCard;
  componentTheme: IComponentThemeOptions;
  isArched: boolean;
  idToDataLoader: IIdToLoaderData | undefined;
}

export const ArchBox = styled(Box)((props) => ({
  borderRadius: props.isArched ? '99em 99em 8em 8em' : `24px`,
}));

const AccordionCardDetails = ({
  card,
  isArched,
  componentTheme,
  idToDataLoader,
}: IComponentMulticardCardProps) => (
  <>
    {card.fields.image?.fields?.file?.url && (
      <CardImage
        isArched={isArched}
        src={card.fields.image?.fields?.file?.url}
        alt={card.fields.image?.fields?.description || card.fields.image?.fields?.title}
      />
    )}
    <SelectiveSpritesheet icons={[ChevronDown]} />
    <Accordion
      title={
        <Box
          display="grid"
          gridRow="1 / -1"
          gridTemplateRows="subgrid"
          rowGap={Gutter}
          width="100%"
        >
          {card.fields.eyebrow && <Eyebrow>{card.fields.eyebrow}</Eyebrow>}
          <Text as="h3" color={componentTheme.color} size="150" fontWeight="medium" m="0">
            {card.fields.title}
          </Text>
          {card.fields.subhead && (
            <Text as="p" color={componentTheme.color} size="110" fontWeight="medium">
              {card.fields.subhead}
            </Text>
          )}
        </Box>
      }
      body={
        card?.fields?.description ? (
          <RichTextRenderer.Component
            body={card.fields.description}
            idToLoaderData={idToDataLoader as IIdToLoaderData}
            entryLookup={articleEntryLookup}
          />
        ) : (
          <Box />
        )
      }
    />
  </>
);

const CardSectionHeader = ({ entry }: { entry: IComponentMulticard }) => {
  const { fields } = entry;
  return (
    <Box
      display={[null, null, 'grid']}
      gap={Gutter}
      gridTemplateColumns="repeat(12, minmax(0, 1fr))"
      mb={10}
    >
      <Box __css={{ ...StackStyle }} gridColumn="1 / -1">
        {fields.title && (
          <TitleWithAccent
            title={fields.title}
            titleAccent={fields.titleAccent}
            titleSize={fields.titleSize === 'Large' ? ['64px', null, '88px'] : ['64px']}
          />
        )}
        {(fields.subhead || fields.desc) && (
          <Box __css={{ ...StackStyle }} maxW={[null, null, '48ch']}>
            {fields.subhead &&
              (fields.titleSize === 'Large' ? (
                <Subhead>{fields.subhead}</Subhead>
              ) : (
                <SubheadSmall>{fields.subhead}</SubheadSmall>
              ))}
            {fields.desc && <Desc $largerThanLG={{ fontSize: 24 }}>{fields.desc}</Desc>}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const RenderMulticard = (
  entry: IComponentMulticard,
  _: Awaited<ReturnType<any>>,
  context?: IRenderContext,
) => {
  const { fields } = entry;
  const idToDataLoader = context?.idToLoaderData;
  const columnNumber = fields.columns ? columns[fields.columns] : 3;
  const componentTheme = fields.cardBackgroundColor
    ? getComponentThemeColors(fields.cardBackgroundColor)
    : getComponentThemeColors('White 0');
  const isArched = fields.cardStyle === 'Arch';

  return (
    <Box gridColumn="1/-1">
      {fields.title && (
        <Container>
          <CardSectionHeader entry={entry} />
        </Container>
      )}
      <CardCarouselV2
        id={entry.sys.id}
        cards={fields.cards}
        componentTheme={componentTheme}
        columnNumber={columnNumber}
        isArched={isArched}
        renderCard={(card) => (
          <ArchBox
            p={card.fields.image?.fields?.file?.url ? '8px 24px 8px' : '88px 24px 24px'}
            background="#F2F2F2"
            height="min-content"
            isArched={isArched}
          >
            <AccordionCardDetails
              card={card}
              componentTheme={componentTheme}
              isArched={isArched}
              idToDataLoader={idToDataLoader}
            />
          </ArchBox>
        )}
      />
    </Box>
  );
};

// https://app.contentful.com/spaces/bjlp9d7o6h1o/content_types/componentMulticard/fields
const Multicard: EntryComponent<IComponentMulticard> = {
  render: RenderMulticard,
};

export default Multicard;
