import React, { Fragment, MouseEvent } from 'react';

import { Box, Flex, Image, Link, Text } from '@opendoor/bricks/core';
import { Entry } from 'contentful';

import { fetchEntriesByTags } from '../../../cms';
import { Awaited, EntryComponent } from '../../../cms/entries/entries';
import {
  IComponentExternalLink,
  IComponentRelatedExternalLinks,
} from '../../../declarations/contentful';
import { getSkipOffset, PAGE_ITEMS_LIMIT } from '../../../helpers/pagination';
import typography from '../typography';

const ExternalLinkCard: React.FC<{
  externalLink: IComponentExternalLink;
}> = ({ externalLink }) => {
  const externalSourceImageUrl =
    externalLink?.fields?.externalLinkSource?.fields?.logo?.fields?.file?.url;
  return (
    <Flex
      height={['fit-content', '140px', '160px', '180px']}
      cursor="pointer"
      flexDirection="row"
      justifyContent="space-between"
      px={6}
      py={[5, 6]}
      borderRadius="semiRounded"
      boxShadow="z1"
      alignItems="center"
      gap="5"
    >
      <Box>
        <Link
          aria-label="external link card"
          href={externalLink.fields?.link}
          analyticsName="cosmos-topicpage-external-link-card"
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
          }}
        >
          <Text size="s0" lineHeight="s0" textColor="neutrals90">
            {externalLink?.fields?.externalLinkSource?.fields?.name}
          </Text>
          <Text noOfLines={2} {...typography.h4} as="h4" mt={[3, null, null, 5]} mb={3}>
            {externalLink.fields?.title}
          </Text>
          <Flex>
            <Text mt={3} size="s0" lineHeight="s0" textColor="neutrals90">
              {/* Publish date */}
              {externalLink.fields?.date
                ? new Date(externalLink.fields.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                : ''}{' '}
            </Text>
          </Flex>
        </Link>
      </Box>
      {externalSourceImageUrl && (
        <Box width="80px" height="80px" position="relative">
          <Image
            position="absolute"
            src={externalSourceImageUrl}
            alt={externalLink?.fields?.externalLinkSource?.fields?.name || ''}
            objectFit="cover"
          />
        </Box>
      )}
    </Flex>
  );
};

const renderExternalLinks = (
  entry: IComponentRelatedExternalLinks,
  resolvedData?: Awaited<ReturnType<typeof externalLinksLoader>>,
) => {
  const externalLinks: Array<IComponentExternalLink> =
    entry?.fields?.items?.slice(0, entry?.fields?.limit) ?? resolvedData?.externalLinks ?? [];

  return (
    <Flex width="100%" flexDirection="column" justifyContent="center" alignItems="center">
      <Box
        display="grid"
        gridTemplateColumns={['auto', 'auto', 'auto auto']}
        gridGap={[6, 6, 7, 7]}
        width="100%"
      >
        {externalLinks.map((externalLink, index) => (
          <Fragment key={index}>
            <ExternalLinkCard externalLink={externalLink} />
          </Fragment>
        ))}
      </Box>
      {entry?.fields?.topicPage?.fields?.slug && (
        <Box mt="8">
          <Link
            fontWeight="semibold"
            analyticsName="cosmos-related-external-links-show-more"
            aria-label="cosmos-topicpage-related-external-links-show-more"
            href={entry?.fields?.topicPage?.fields?.slug}
          >
            Show more {'>'}
          </Link>
        </Box>
      )}
    </Flex>
  );
};

interface IExternalLinksLoaderReturn {
  externalLinks: Array<any>;
  total: number | undefined;
}

const externalLinksLoader = async (
  input: IComponentRelatedExternalLinks,
  _root?: Entry<IComponentExternalLink>,
  pageProps = { paginationIndex: 0 },
) => {
  const loaderReturn: IExternalLinksLoaderReturn = {
    externalLinks: [],
    total: 0,
  };
  let externalLinks: Array<IComponentExternalLink | Entry<IComponentExternalLink>> =
    input.fields?.items || [];

  if (externalLinks.length === 0) {
    // Auto selection if no external links were selected
    const tags = input?.metadata?.tags
      ?.filter((item) => item?.sys?.type === 'Link' && item?.sys?.linkType === 'Tag')
      ?.map((item) => item?.sys?.id);
    if (tags?.length > 0) {
      const externalLinksCollection = await fetchEntriesByTags<IComponentExternalLink>(
        'componentExternalLink',
        tags,
        {
          select: 'fields.title,fields.date,fields.link,fields.externalLinkSource',
          skip: getSkipOffset(pageProps.paginationIndex),
          /*
            The field "include" specifies how many levels down of nested
            data that this fetch call should return. Please be aware that any
            value greater than 2 has significant impact on page performance.
          */
          include: 2,
          limit: input.fields?.limit || PAGE_ITEMS_LIMIT,
          order: '-fields.date',
        },
      );
      externalLinks = externalLinksCollection?.items || [];
      loaderReturn.total = externalLinksCollection?.total;
      loaderReturn.externalLinks = externalLinks;
    }
  }

  return loaderReturn;
};

const ExternalLinks: EntryComponent<
  IComponentRelatedExternalLinks,
  Awaited<ReturnType<typeof externalLinksLoader>>
> = {
  render: renderExternalLinks,
  loader: externalLinksLoader,
};

export default ExternalLinks;
