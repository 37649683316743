import { Flex, H5, Link, Text } from '@opendoor/bricks/core';

import { EntryComponent } from 'cms/entries/entries';

import { IComponentSimpleCta } from '../../declarations/contentful';

const renderSimpleCTA = (entry: IComponentSimpleCta) => {
  const { fields } = entry;
  return (
    <Flex padding={9} alignItems={'center'} background="neutrals10" flexDirection={'column'}>
      {fields.headline && (
        <H5 textAlign={'center'} margin={0} color={'neutrals100'}>
          {fields.headline}
        </H5>
      )}
      {fields.description && (
        <Text
          textAlign={'center'}
          marginY={5}
          fontSize={['s1', 's2', null, null]}
          color={'neutrals90'}
        >
          {fields.description}
        </Text>
      )}
      {fields.ctaLink && fields.ctaText && (
        <Link
          href={fields.ctaLink}
          target="_blank"
          variant="primary-button"
          aria-label="cta-link"
          analyticsName={'simple-cta-download-link'}
        >
          {fields.ctaText}
        </Link>
      )}
    </Flex>
  );
};

const SimpleCTA: EntryComponent<IComponentSimpleCta> = {
  render: renderSimpleCTA,
};

export default SimpleCTA;
