import * as contentful from 'contentful';
import { Entry } from 'contentful';

import { fetchArticlesByTags } from '../../cms';
import { Awaited, EntryComponent } from '../../cms/entries/entries';
import { IComponentRelatedArticles } from '../../declarations/contentful';
import ArticleCard from '../articles/ArticleCard';
import CardCarousel, { CarouselItem } from '../shared/CardCarousel';
import { articleLiteFields, IArticleLite } from './topicPage/Articles';

export type RelatedArticlesLoaderReturn = Awaited<ReturnType<typeof articlesCardsLoader>>;

const renderArticlesCards = (
  entry: IComponentRelatedArticles,
  resolvedData?: RelatedArticlesLoaderReturn,
) => {
  // For which related articles to display: if manual selections are
  // available, prioritize them over query results
  let relatedArticles: Array<Entry<IArticleLite>> =
    entry?.fields?.items
      // Ensure that if we have a missing slug (self-reference), we just remove it
      ?.filter((item) => !!item?.fields) ||
    resolvedData?.articles?.filter((item) => !!item?.fields) ||
    [];
  if (entry.fields?.limit) {
    relatedArticles = relatedArticles.slice(0, entry.fields?.limit);
  }

  return (
    <CardCarousel height={['380px', '460px', '395px', '365px', '365px']}>
      {relatedArticles.map((article, index) => (
        <CarouselItem key={`carousel-item-${index}-${article.sys.id}`}>
          <ArticleCard
            articleName={article?.fields?.articleName}
            imageSourceUrl={article?.fields.openGraph?.fields?.image}
            slug={article?.fields?.slug}
            publishDate={article?.fields?.publishDate}
            tags={article.metadata.tags.map((tag) => tag.sys.id) || []}
          />
        </CarouselItem>
      ))}
    </CardCarousel>
  );
};

const articlesCardsLoader = async (
  input: IComponentRelatedArticles,
): Promise<{ articles: Array<Entry<IArticleLite>> }> => {
  if (!input.metadata.tags || input.metadata.tags?.length === 0) {
    return { articles: [] };
  }

  const tags = input.metadata.tags.map((tag: contentful.TagLink) => tag.sys.id);
  const articles = await fetchArticlesByTags<IArticleLite>(tags, {
    limit: input.fields.limit,
    select: articleLiteFields,
  });
  return { articles: articles?.items ?? [] };
};

const RelatedArticlesCards: EntryComponent<IComponentRelatedArticles, RelatedArticlesLoaderReturn> =
  {
    render: renderArticlesCards,
    loader: articlesCardsLoader,
  };

export default RelatedArticlesCards;
