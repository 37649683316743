import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Flex, Text } from '@opendoor/bricks/core';

import { EntryComponent } from '../../../cms/entries/entries';
import { EmailInput } from '../../../components/shared/EmailInput';
import { H3 } from '../../../components/shared/Heading';
import { IComponentEmailHero } from '../../../declarations/contentful';
import typography from '../typography';

const EmailHeroComponent = (entry: IComponentEmailHero) => {
  const { fields } = entry;
  const title = fields?.h1 ? documentToReactComponents(fields?.h1) : null;
  const subtitle = fields?.h2 ? documentToReactComponents(fields?.h2) : null;
  const desktopHeroImage = fields?.desktopImage?.fields?.file?.url;
  const mobileHeroImage = fields?.mobileImage?.fields?.file?.url;

  return (
    <Flex justifyContent="space-between" flexDir={['column', null, 'row']}>
      <Box
        borderRadius="roundedSquare"
        width="100%"
        minHeight={['580px', null, '360px']}
        my={[6, 8]}
        py={8}
        px={8}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundImage={[`url(${mobileHeroImage})`, null, `url(${desktopHeroImage})`]}
        mb={[6, null, 0]}
      >
        <Flex flexDir="column" height="100%" justifyContent="space-between">
          <Flex flexDir="column">
            <H3 {...typography.h3} color="neutrals100" mb={7}>
              {title}
            </H3>
          </Flex>
          <Box width={['100%', '100%', '50%']} py={4}>
            <EmailInput campaignName="topicPage" ctaText="Sign up" />
          </Box>
          <Text fontWeight="normal" fontSize="s0" color="neutrals90" lineHeight="s00" mt={17}>
            {subtitle}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

const EmailHero: EntryComponent<IComponentEmailHero> = {
  render: EmailHeroComponent,
};

export default EmailHero;
