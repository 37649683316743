import React, { useEffect, useState } from 'react';

import { Box, Text, TextInput, useMedia } from '@opendoor/bricks-next';

import { sellType } from './NetProceedsComparisonCalculator';

export const opendoorIdsWithNoTextInput = [
  'saleType',
  'daysToFindABuyer',
  'daysToCloseTransaction',
  'realEstateAgentFees',
  'sellerConcessions',
  'monthsRenting',
  'monthlyRentalCost',
];
export const traditionalIdsWithNoTextInput = ['saleType', 'opendoorServiceCharge'];
interface FeeInputProps {
  id: string;
  title: string;
  odUnit: string;
  trUnit: string;
  odEditable: boolean;
  trEditable: boolean;
  supportText?: string;
  odDefaultValue: string;
  trDefaultValue: string;
  onValueChange: (id: string, value: number, type: sellType) => void;
}

const FeeInput: React.FC<FeeInputProps> = React.memo(
  ({
    id,
    title,
    trUnit,
    supportText,
    odDefaultValue,
    trDefaultValue,
    odUnit,
    odEditable,
    trEditable,
    onValueChange,
  }) => {
    const [odFee, setOdFee] = useState<string>(odDefaultValue);
    const [trFee, setTrFee] = useState<string>(trDefaultValue);
    const { largerThanMD, largerThanSM } = useMedia();

    useEffect(() => {
      setOdFee(odDefaultValue);
    }, [odDefaultValue]);

    useEffect(() => {
      setTrFee(trDefaultValue);
    }, [trDefaultValue]);

    const validateAndSetFee = (value: string, type: sellType) => {
      const updatedValue = value.replace(/,/g, '');
      const numericValue = Number(updatedValue);

      if (!isNaN(Number(numericValue))) {
        type === 'opendoor' ? setOdFee(updatedValue) : setTrFee(updatedValue);
        onValueChange(id, numericValue, type);
      }
      return;
    };

    return (
      <Box
        py="$12x"
        gap="$6x"
        $largerThanMD={{ alignItems: 'center', gap: '$28x', py: '$4x' }}
        $largerThanSM={{ flexDirection: 'row', alignItems: 'center', py: '$4x', gap: '$16x' }}
      >
        <Box
          style={{ flex: 1 }}
          paddingRight="$28x"
          $largerThanMD={{ paddingRight: '$28x' }}
          $largerThanSM={{ paddingRight: '$16x' }}
        >
          <Text
            $largerThanMD={{ typography: '$labelXlarge' }}
            typography="$labelMedium"
            tag="h2"
            color={'$contentPrimary'}
          >
            {title}
          </Text>
          <Text
            $largerThanMD={{ typography: '$bodyMedium' }}
            typography="$bodySmall"
            tag="span"
            color="$contentTertiary"
          >
            {supportText}
          </Text>
        </Box>
        <Box
          style={{ flex: 1 }}
          flexDirection="row"
          $largerThanMD={{ gap: '$24x' }}
          gap="$12x"
          alignItems="center"
        >
          <Box style={{ flex: 1 }} testID="odInput">
            {opendoorIdsWithNoTextInput.includes(id) ? (
              <Box
                $largerThanSM={{ py: '$11x', px: '$11x' }}
                $largerThanMD={{ py: '$13x', paddingTop: '$5x', px: 0 }}
                justifyContent="center"
              >
                <Text
                  $largerThanMD={{ typography: '$bodyXlarge', py: '$13x' }}
                  $largerThanSM={{ typography: '$bodyXlarge', py: '$11x' }}
                  typography="$bodyMedium"
                  tag="h2"
                  mt={largerThanMD ? '$12x' : 0}
                >
                  {odDefaultValue + ' ' + odUnit}
                </Text>
              </Box>
            ) : (
              <TextInput
                disabled={!odEditable}
                id={id + 'opendoor'}
                value={Number(odFee).toLocaleString('en-US')}
                onChange={(event: any) => {
                  validateAndSetFee(event.target.value, 'opendoor');
                }}
                size={largerThanSM ? 'large' : 'small'}
                //Do not pass title for offerPrice or smaller screens
                {...(id !== 'offerPrice' || !largerThanSM
                  ? { ariaLabel: title }
                  : { title: 'Opendoor' })}
                prefix={odUnit === '$' ? '$' : ''}
                suffix={odUnit === '$' ? '' : odUnit}
              ></TextInput>
            )}
          </Box>
          <Box style={{ flex: 1 }} testID="trInput">
            {traditionalIdsWithNoTextInput.includes(id) ? (
              <Box
                justifyContent="center"
                $largerThanSM={{ py: '$11x' }}
                $largerThanMD={{ py: '$13x', paddingTop: '$5x' }}
              >
                <Text
                  typography="$bodyMedium"
                  $largerThanMD={{ typography: '$bodyXlarge', py: '$13x' }}
                  $largerThanSM={{ typography: '$bodyXlarge', py: '$11x' }}
                  tag="h2"
                  color={
                    id === 'opendoorServiceCharge'
                      ? '$contentStateInactivePrimary'
                      : '$contentPrimary'
                  }
                  mt={largerThanMD ? '$12x' : 0}
                >
                  {trDefaultValue + ' ' + trUnit}
                </Text>
              </Box>
            ) : (
              <Box>
                <TextInput
                  disabled={!trEditable}
                  id={id + 'traditional'}
                  //To introduce comma separated string but exclude range based strings
                  value={id == 'daysToFindABuyer' ? trFee : Number(trFee).toLocaleString('en-US')}
                  onChange={(event: any) => {
                    validateAndSetFee(event.target.value, 'traditional');
                  }}
                  size={largerThanSM ? 'large' : 'small'}
                  //Do not pass title for offerPrice or smaller screens
                  {...(id !== 'offerPrice' || !largerThanSM
                    ? { ariaLabel: title }
                    : { title: 'Traditional' })}
                  prefix={trUnit === '$' ? '$' : ''}
                  suffix={trUnit === '$' ? '' : trUnit}
                ></TextInput>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);
FeeInput.displayName = 'FeeInput';
export default FeeInput;
