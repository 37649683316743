import { IAddressInputAddress } from '@opendoor/cinderblocks/growth/AddressInput/AddressInput';
import { analytics, globalObservability } from '@opendoor/observability/slim';

import {
  OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product,
  OdProtosSellReceptionData_SellerInput_Channel,
} from '__generated__/athena';

import * as api from './';
import { CONSUMER_FE_URL } from '../globals';
import { setCookie } from '../shared/trackers';

const Sentry = globalObservability.getSentryClient();
interface IPathParams {
  token: string;
  page: string;
  inApp?: boolean;
}

const sellerGlobalPagePath = ({ token, page, inApp = false }: IPathParams) => {
  let path = `/seller-lead/${token}/p/${page}`;
  if (inApp) {
    path = `${path}?app=1`;
  }
  return path;
};

export const bffSellerGlobalPagePath = ({ token, page, inApp = false }: IPathParams) => {
  return CONSUMER_FE_URL + sellerGlobalPagePath({ token, page, inApp });
};

export const getSellerFlowUUID = () => {
  const fragments = window.location.search.match(/sfuuid=([-\w]+)/);
  if (!fragments) {
    return undefined;
  }
  return fragments[1];
};

export const getUrlParam = (paramName: string) => {
  const regExp = new RegExp(`${paramName}\\=([\\w-_]+)`);
  const fragments = window.location.search.match(regExp);
  if (!fragments) {
    return undefined;
  }
  return fragments[1];
};

export const getSellerFlowName = () => {
  return getUrlParam('sfname');
};

export const getCovid19Whitelist = () => {
  return getUrlParam('covid19_whitelist') === 'true';
};

export const getCustomExperimentEntityId = () => {
  return getUrlParam('experiment_entity_id');
};

export const getChannelOverride = () => {
  return getUrlParam('channel_override')?.toUpperCase();
};

const GRPC_ERROR_CODE = 'GRPC_ERROR';
const INVALID_ARGUMENT_ERROR_CODE = 3;

type AddressVerificationError = {
  type: 'invalid-address' | 'generic-error';
  message: string;
};

/** Determines whether the errors array includes a invalid argument error */
const hasInvalidArgumentError = (errors: Array<any>): boolean => {
  return (
    errors.some(
      (error: any) =>
        error.extensions?.code === GRPC_ERROR_CODE &&
        error.extensions?.exception?.code === INVALID_ARGUMENT_ERROR_CODE,
    ) || false
  );
};

export const startAddressVerification = async ({
  address,
  product,
  channel,
  trackingKeywords,
  inputLocation,
  onSuccess,
  partnerReferralId,
}: {
  address: IAddressInputAddress;
  product: OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product;
  channel?: OdProtosSellReceptionData_SellerInput_Channel;
  trackingKeywords?: string;
  inputLocation?: string;
  onSuccess?: (token: string, pdpUrl?: string) => void;
  partnerReferralId?: string;
}): Promise<AddressVerificationError> => {
  analytics.trackEvent('inputting', 'address', 'verifying', {
    ...address,
    inputLocation,
  });

  const addressEntry = {
    product,
    url: window.location.href,
  };

  const options = {
    channel: getChannelOverride() || channel,
    covid19Whitelist: getCovid19Whitelist(),
    seller_flow_name_override: getSellerFlowName(),
    seller_flow_uuid_override: getSellerFlowUUID(),
    trackingKeywords,
    partnerReferralId,
  };

  const error = await api
    .createSellerInput(address, addressEntry, options)
    .then((resp) => {
      if (!resp) {
        throw {
          type: 'generic-error',
          message: 'received non-200 error or could not json parse response, check network logs',
        };
      }
      if (resp.errors?.length) {
        if (hasInvalidArgumentError(resp.errors)) {
          throw {
            type: 'invalid-address',
            message: resp.errors,
          };
        }
        throw { type: 'generic-error', message: resp.errors };
      }
      const sellerInput =
        resp?.data?.reception?.createSellerInputWithTrackingDataFromCookies?.sellerInput;
      if (!sellerInput) {
        // in case an error is not returned in the response, but seller input is empty.
        throw {
          type: 'generic-error',
          message:
            'startAddressVerification: seller input from CreateSellerInputWithTrackingDataFromCookies response is undefined',
        };
      }
      const { uuid, pdpUrl, isInPartnershipDenialServiceRegions } = sellerInput;
      const postalCode = sellerInput.address.postalCode;
      if (address.postal_code && postalCode !== address.postal_code) {
        throw {
          type: 'invalid-address',
          message: `startAddressVerification: postal code mismatch: ${postalCode} !== ${address.postal_code}`,
        };
      }
      setCookie(
        `isInPartnershipDenialServiceRegions-${uuid}`,
        isInPartnershipDenialServiceRegions.toString(),
        1,
      );
      analytics.register({ 'Offer ID': uuid, 'Offer Postal Code': postalCode });
      onSuccess && onSuccess(uuid, pdpUrl);
      return;
    })
    .catch((e) => {
      analytics.trackEvent('lead', 'failed');
      Sentry.captureException?.(e.message, {
        captureContext: {
          extra: {
            address: address,
            addressEntry: addressEntry,
            options: options,
          },
        },
      });
      return e;
    });

  return error;
};
