import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@opendoor/bricks-next';
import { Box, Image, Link } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentStandaloneText } from 'declarations/contentful';

import Container from './shared/Container';
import { Gutter } from './shared/styles/SharedStyles';
import { Subhead, SubheadSmall, TitleWithAccent } from './shared/Typography';

const bodyParser = ({ gridColumnConfig }: any) => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      const {
        fields,
        sys: { contentType },
      } = node.data.target;
      return (
        <Box
          id={fields.elementID}
          gridColumn={fields.size === 'Large' ? '1 / -1' : gridColumnConfig}
          css={{ scrollMarginTop: '80px' }}
        >
          {contentType.sys?.id === 'lpComponentMedia' ? (
            <Image
              borderRadius="semiRounded"
              src={fields.image.fields?.file?.url}
              alt={fields.image.fields?.description || fields.image.fields?.title}
            />
          ) : (
            documentToReactComponents(fields?.title)
          )}
        </Box>
      );
    },
  },
});

const ListItem = styled.li`
  font-family: ${novo.fonts.sans};
  font-weight: ${novo.fontWeights.regular};
  color: ${novo.colors.warmgrey800};
  font-size: ${novo.fontSizes['16px']};
  line-height: ${novo.lineHeights['120']};
  letter-spacing: ${novo.letterSpacings['-2.4']};
  margin-bottom: 24px;

  a {
    color: ${novo.colors.brandBlue600};
    display: block;
    font-weight: ${novo.fontWeights.medium};
    margin-bottom: 8px;
  }
  a:hover,
  a:focus {
    color: ${novo.colors.blue500};
  }
`;

const sidebarParse: Options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Text
        tag="h2"
        color="$contentSecondary"
        typography="$headerMedium"
        fontWeight="$medium"
        marginBottom={24}
        paddingBottom={24}
        borderBottomColor="$borderPrimary"
      >
        {children}
      </Text>
    ),
    [BLOCKS.UL_LIST]: (_, children) => (
      <Box
        as="ul"
        role="list"
        m="0 0 24px 0"
        p="0"
        css={css`
          list-style: none;
        `}
      >
        {children}
      </Box>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => <ListItem>{children}</ListItem>,
  },
};

const stackSpacing = `
> * + * {
  margin-top: 32px;
}
@media (min-width: 769px) {
  > * + * {
    margin-top: 64px;
  }
}`;

const paragraphStyle = `
> p {
  font-size: ${novo.fontSizes['18px']};
  letter-spacing: ${novo.letterSpacings['-2.4']};
  line-height: ${novo.lineHeights['150']};
}`;

const bqBase = `
  font-size: ${novo.fontSizes['40px']};
  letter-spacing: ${novo.letterSpacings['-3']};
  line-height: ${novo.lineHeights['100']};
  margin-bottom: 0;
  margin-left: 32px;
  margin-right: 0;
  position: relative;

  @media (min-width: 769px) {
    margin-left: 0;
  }
`;

const bqBefore = `
  content: '“';
  display: inline-block;
  position: absolute;
  right: calc(100% + 16px);
`;

const outerMarginSpacing = ['24px', null, '32px'];

const Intro = styled(Box)`
  > * {
    margin-bottom: 32px;
  }
  @media (min-width: 769px) {
    columns: auto 3;
    column-gap: 24px;
    margin-bottom: 64px;

    > * {
      margin-bottom: 0;
    }
  }
  ${paragraphStyle}
`;

// dialogId is for instances where the Standalone text is in a Sheet
const Header = ({ entry, dialogId }: { entry: ILpComponentStandaloneText; dialogId?: string }) => {
  const { fields } = entry;
  return (
    <Box
      as="header"
      mb={outerMarginSpacing}
      css={css`
        ${stackSpacing}
      `}
    >
      {fields.title && (
        <TitleWithAccent
          title={fields.title}
          titleAccent={fields.titleAccent}
          titleSize={fields.titleSize}
          marginHorizontal={fields.layout === 'Centered' ? 'auto' : undefined}
          id={dialogId || undefined}
        />
      )}
      {fields.subhead &&
        (fields.titleSize === 'Large' ? (
          <Subhead>{fields.subhead}</Subhead>
        ) : (
          <SubheadSmall>{fields.subhead}</SubheadSmall>
        ))}
    </Box>
  );
};

const Cta = ({ entry }: { entry: ILpComponentStandaloneText }) => {
  const { fields } = entry;

  return (
    <Link
      aria-label={fields.ctaDisplayText || null}
      analyticsName={`cosmos-landing-page-standalone-text-${fields?.analyticsId}`}
      href={fields.ctaUrl}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      gap="8px"
      flex="1"
      mt={outerMarginSpacing}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
      >
        <path
          fill="currentColor"
          d="M14.1664 6.59103C14.2249 6.71532 14.2541 6.85157 14.2541 6.99976C14.2541 7.14317 14.2249 7.27941 14.1664 7.40848C14.1128 7.53277 14.0252 7.65228 13.9034 7.76701L8.28378 13.1665C8.09378 13.3481 7.86238 13.439 7.58956 13.439C7.41417 13.439 7.25097 13.3959 7.09995 13.3099C6.94892 13.2239 6.82713 13.1091 6.73456 12.9657C6.64687 12.8223 6.60303 12.6598 6.60303 12.4781C6.60303 12.2152 6.70533 11.9809 6.90995 11.7754L10.7761 8.08691H1C0.447715 8.08691 0 7.6392 0 7.08691C0 6.53463 0.447715 6.08691 1 6.08691H10.9589L6.90995 2.22413C6.70533 2.02335 6.60303 1.78911 6.60303 1.52141C6.60303 1.34453 6.64687 1.18439 6.73456 1.04098C6.82713 0.892785 6.94892 0.775665 7.09995 0.689618C7.25097 0.603571 7.41418 0.560547 7.58956 0.560547C7.86238 0.560547 8.09379 0.651375 8.28378 0.83303L13.9034 6.2325C14.0203 6.34723 14.108 6.46674 14.1664 6.59103Z"
        />
      </svg>
      <Text
        marginTop={-1}
        tag="p"
        typography="$bodyMedium"
        fontWeight="$medium"
        color="$backgroundAccentBrand"
        whiteSpace="nowrap"
      >
        {fields.ctaDisplayText}
      </Text>
    </Link>
  );
};

const SidebarLayout = ({ entry }: { entry: ILpComponentStandaloneText }) => {
  const { fields } = entry;
  return (
    <>
      <Box
        display={[null, null, 'grid']}
        columnGap={Gutter}
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
      >
        <Box gridColumn="1 / 9">
          {(fields.title || fields.subhead) && <Header entry={entry} />}
          {fields.intro && <Intro>{documentToReactComponents(fields.intro)}</Intro>}
          <Box
            display={[null, null, 'grid']}
            columnGap={Gutter}
            gridTemplateColumns="repeat(8, minmax(0, 1fr))"
            mb={[Gutter, null, 0]}
            css={css`
              > h1,
              > h2,
              > h3,
              > h4,
              > h5,
              > h6,
              > p,
              > ul,
              > ol {
                grid-column: 1 / 7;
              }
              > blockquote {
                grid-column: 2 / -1;
                ${bqBase}
              }
              > blockquote::before {
                ${bqBefore}
              }
              ${paragraphStyle}
              ${stackSpacing}
            `}
          >
            {fields.body &&
              documentToReactComponents(fields.body, bodyParser({ gridColumnConfig: '1 / 7' }))}
          </Box>
        </Box>
        {fields.sidebar && (
          <Box gridColumn="10 / -1">{documentToReactComponents(fields.sidebar, sidebarParse)}</Box>
        )}
      </Box>
      {fields.ctaDisplayText && fields.ctaUrl && <Cta entry={entry} />}
    </>
  );
};

// Centered Layout is also used by Sheets
// dialogId is for instances where the Standalone text is in a Sheet
export const CenteredLayout = ({
  entry,
  dialogId,
}: {
  entry: ILpComponentStandaloneText;
  dialogId?: string;
}) => {
  const { fields } = entry;
  return (
    <Box
      display={[null, null, 'grid']}
      columnGap={Gutter}
      gridTemplateColumns="repeat(12, minmax(0, 1fr))"
    >
      <Box
        gridColumn="3 / -3"
        textAlign={['left', null, 'center']}
        css={css`
          ${stackSpacing}
        `}
      >
        {(fields.title || fields.subhead) && <Header entry={entry} dialogId={dialogId} />}
      </Box>
      <Box
        display={[null, null, 'grid']}
        columnGap={Gutter}
        gridColumn="3 / -3"
        gridTemplateColumns="repeat(8, minmax(0, 1fr))"
        textAlign={['left', null, 'center']}
        css={css`
          > h1,
          > h2,
          > h3,
          > h4,
          > h5,
          > h6,
          > p,
          > ul,
          > ol,
          > blockquote {
            grid-column: 2 / -2;
          }
          > blockquote {
            ${bqBase}
            margin-left: 0;
          }
          > blockquote::before {
            content: '“';
            display: inline-block;
          }
          ${paragraphStyle}
          ${stackSpacing}
        `}
      >
        {fields.intro && <Intro>{documentToReactComponents(fields.intro)}</Intro>}
        {fields.body &&
          documentToReactComponents(fields.body, bodyParser({ gridColumnConfig: '2 / -2' }))}
      </Box>
      <Box gridColumn="4 / -4" textAlign={['left', null, 'center']}>
        {fields.ctaDisplayText && fields.ctaUrl && <Cta entry={entry} />}
      </Box>
    </Box>
  );
};

const DefaultLayout = ({ entry }: { entry: ILpComponentStandaloneText }) => {
  const { fields } = entry;

  return (
    <>
      {(fields.title || fields.subhead) && <Header entry={entry} />}

      {fields.intro && <Intro>{documentToReactComponents(fields.intro)}</Intro>}
      <Box
        display={[null, null, 'grid']}
        columnGap={Gutter}
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        css={css`
          > h1,
          > h2,
          > h3,
          > h4,
          > h5,
          > h6,
          > p,
          > ul,
          > ol {
            grid-column: 1 / 7;
          }
          > blockquote {
            grid-column: 2 / 11;
            ${bqBase}
          }
          > blockquote::before {
            ${bqBefore}
          }
          ${paragraphStyle}
          ${stackSpacing}
        `}
      >
        {fields.body &&
          documentToReactComponents(fields.body, bodyParser({ gridColumnConfig: '1 / 7' }))}
      </Box>
      {fields.ctaDisplayText && fields.ctaUrl && <Cta entry={entry} />}
    </>
  );
};

const RenderStandaloneText = (entry: ILpComponentStandaloneText) => {
  const { fields } = entry;

  return (
    <Container>
      {fields.layout === 'Sidebar' ? (
        <SidebarLayout entry={entry} />
      ) : fields.layout === 'Centered' ? (
        <CenteredLayout entry={entry} />
      ) : (
        <DefaultLayout entry={entry} />
      )}
    </Container>
  );
};

const StandaloneText: EntryComponent<ILpComponentStandaloneText> = {
  render: RenderStandaloneText,
};

export default StandaloneText;
