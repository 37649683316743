import Multicard from 'components/articles/Multicard';

import AccordionList from '../../components/articles/AccordionList';
import BasicMetric from '../../components/articles/BasicMetric';
import EmailInputComponent from '../../components/articles/EmailInput';
import ExclusivesGallery from '../../components/articles/ExclusivesGallery';
import FooterBox from '../../components/articles/FooterBox';
import HighlightBox from '../../components/articles/HighlightBox';
import IconText from '../../components/articles/IconText';
import Iframe from '../../components/articles/Iframe';
import ImageAsset from '../../components/articles/ImageAsset';
import ImageAssetLink from '../../components/articles/ImageAssetLink';
import ImageCarouselComponent from '../../components/articles/ImageCarousel';
import InstagramImage from '../../components/articles/InstagramImage';
import MetricsCard from '../../components/articles/MetricsCard';
import PropertyListingsComponent from '../../components/articles/PropertyListings';
import Quote from '../../components/articles/Quote';
import RelatedArticlesCards from '../../components/articles/RelatedArticlesCards';
import SimpleCTA from '../../components/articles/SimpleCTA';
import TwitterTweet from '../../components/articles/TwitterTweet';
import IEntryLookup from './entries';

const articleEntryLookup: IEntryLookup = {
  block: {
    componentPropertyListings: PropertyListingsComponent,
    componentMetricsCard: MetricsCard,
    componentIconText: IconText,
    componentRelatedArticles: RelatedArticlesCards,
    componentExclusiveListings: ExclusivesGallery,
    metricBasic: BasicMetric,
    ctaEmailCapture: EmailInputComponent,
    componentImageCarousel: ImageCarouselComponent,
    instagramImageAsset: InstagramImage,
    imageAsset: ImageAsset,
    componentAccordionList: AccordionList,
    componentImageAssetLink: ImageAssetLink,
    componentIframe: Iframe,
    componentQuotes: Quote,
    componentSimpleCta: SimpleCTA,
    componentFooterBox: FooterBox,
    componentTwitter: TwitterTweet,
    componentHighlightBox: HighlightBox,
    componentMulticard: Multicard,
  },
  inline: {
    ctaEmailCapture: EmailInputComponent,
  },
};

export default articleEntryLookup;
