/* storybook-check-ignore */
import styled from '@emotion/styled';
import { Flex } from '@opendoor/bricks/core';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentIframe } from '../../declarations/contentful';

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const renderIframe = (entry: IComponentIframe) => {
  const { fields } = entry;

  const aspectRatio = fields.ratio ? 100 / fields.ratio + '%' : '100%';

  const style = {
    width: '100%',
    paddingBottom: aspectRatio,
  };

  return (
    <Flex
      overflowX="hidden"
      position="relative"
      // align in the center on small screens to reduce excess spacing
      alignItems="center"
      justifyContent="center"
      {...style}
    >
      <IFrame
        title="contenful-iframe-component"
        src={fields.url}
        allow="autoplay; fullscreen"
        allowFullScreen
      ></IFrame>
    </Flex>
  );
};

const IframeComponent: EntryComponent<IComponentIframe> = {
  render: renderIframe,
};

export default IframeComponent;
