import { Flex } from '@opendoor/bricks/core';

import { EntryComponent } from '../../cms/entries/entries';
import {
  IComponentImageCarousel,
  IImageAsset,
  IInstagramImageAsset,
} from '../../declarations/contentful';
import ImageAsset from './ImageAsset';
import InstagramImage from './InstagramImage';

const renderImageCarousel = (entry: IComponentImageCarousel) => {
  return (
    <Flex
      overflowX="auto"
      position="relative"
      gridGap={['4', null, null, '9']}
      // align in the center on small screens to reduce excess spacing
      alignItems="center"
      pb="4"
      pr="4"
      width={['100%', null, null, '100%']}
      marginRight={[null, null, null, '-10%']}
      _hover={{
        // only show on hover, slight indication that it's a carousel
        boxShadow: 'inset -2px 0 4px -2px rgba(0,0,0,0.1);',
      }}
    >
      {entry.fields.images?.map((x) => {
        if (x.sys.contentType.sys.id === 'instagramImageAsset') {
          return InstagramImage.render(x as IInstagramImageAsset);
        }
        if (x.sys.contentType.sys.id === 'imageAsset') {
          return ImageAsset.render(x as IImageAsset);
        }
        return null;
      })}
    </Flex>
  );
};

const ImageCarouselComponent: EntryComponent<IComponentImageCarousel> = {
  render: renderImageCarousel,
};

export default ImageCarouselComponent;
