import { css } from '@emotion/react';
import { Text } from '@opendoor/bricks-next';
import { Box, Flex } from '@opendoor/bricks/core';

import { EntryComponent } from 'cms/entries/entries';

import { Desc } from 'components/landing-pages-v2/shared/Typography';

import { ILpComponentTextSection } from 'declarations/contentful';

import Grid, { gridStyles } from './Grid';

const RenderTextSection = (entry: ILpComponentTextSection) => {
  const { fields } = entry;
  const analyticsName = `cosmos-landing-page-text-section-${fields?.analyticsId}`;

  return (
    <Box analyticsName={analyticsName} trackImpression>
      <Grid>
        <Flex
          gridColumn={gridStyles.colSpan12}
          width="100%"
          flexDirection={['column', null, 'row']}
          mt={[11, '96px', 12, '80px']}
          mb={[10, 11, 11, '80px']}
          justifyContent={[null, null, 'space-between']}
          alignItems={[null, null, 'flex-end']}
          gap={[6, null, 8]}
        >
          <Flex width={['100%', null, 'unset']} flexDir="column" justifyContent="flex-end">
            <Text
              tag="h2"
              typography="$headerSmall"
              display="block"
              color="$contentPrimary"
              alignSelf="baseline"
              $largerThanSM={{ typography: '$headerMedium' }}
              $largerThanMD={{ typography: '$headerLarge' }}
            >
              {fields.title}
            </Text>
            {fields.subtitle && (
              <Text
                tag="span"
                typography="$headerSmall"
                color="$backgroundAccentBrand"
                $largerThanSM={{ typography: '$headerMedium' }}
                $largerThanMD={{ typography: '$headerLarge' }}
              >
                {fields.subtitle}
              </Text>
            )}
          </Flex>
          <Box
            maxWidth={['235px', '235px', '330px', '424px']}
            css={css`
              @media (min-width: 768px) and (max-width: 910px) {
                max-width: 260px;
              }
            `}
          >
            <Desc>{fields.description}</Desc>
          </Box>
        </Flex>
      </Grid>
    </Box>
  );
};

const TextSection: EntryComponent<ILpComponentTextSection> = {
  render: RenderTextSection,
};

export default TextSection;
