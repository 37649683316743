import EmailInputComponent from '../../components/articles/EmailInput';
import ImageAsset from '../../components/articles/ImageAsset';
import Articles from '../../components/articles/topicPage/Articles';
import EmailHero from '../../components/articles/topicPage/EmailHero';
import Experts from '../../components/articles/topicPage/Experts';
import ExternalLinks from '../../components/articles/topicPage/ExternalLinks';
import Hero from '../../components/articles/topicPage/Hero';
import ImageAssetLink from '../../components/articles/topicPage/ImageAssetLink';
import InfoCard from '../../components/articles/topicPage/InfoCard';
import MediaAssetSection from '../../components/articles/topicPage/MediaAssetSection';
import NavBar from '../../components/articles/topicPage/NavBar';
import TopTopics from '../../components/articles/topicPage/TopTopics';
import IEntryLookup from './entries';

const topicPageEntryLookup: IEntryLookup = {
  block: {
    componentRelatedArticles: Articles,
    ctaEmailCapture: EmailInputComponent,
    componentTopTopics: TopTopics,
    componentExperts: Experts,
    componentRelatedExternalLinks: ExternalLinks,
    componentEmailHero: EmailHero,
    componentHeroV2: Hero,
    componentMediaAssetSection: MediaAssetSection,
    componentImageAssetLink: ImageAssetLink,
    componentInfoCard: InfoCard,
    componentNavBar: NavBar,
    imageAsset: ImageAsset,
  },
  inline: {},
};

export default topicPageEntryLookup;
