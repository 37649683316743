import styled from '@emotion/styled';
import { Box, Flex, H2, Text } from '@opendoor/bricks/core';
import { ColorScheme } from '@opendoor/bricks/system/theme';
import { Token } from '@opendoor/bricks/system/token.types';
import { colors } from '@opendoor/bricks/theme/eero';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentFooterBox } from '../../declarations/contentful';
import { SellerFlowAddressInput } from '../shared/SellerFlowAddressInput';
import typography from './typography';

const DownloadButton = styled.a`
  @media (max-width: 767px) {
    display: inline-block;
    margin: 0 5px;
  }
`;

const UL = styled.ul<{ buyer: boolean }>`
  margin: 0 0 40px;
  padding: 0;
  list-style: none;

  & li {
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 500;
    padding-left: 30px;
  }

  & li:before {
    content: ''; //✓
    position: absolute;
    filter: ${(props) => (props.buyer ? 'brightness(0)' : undefined)};
    left: 0;
    top: 7px;
    width: 18px;
    height: 14px;
    background-size: cover;
    background-image: url('https://images.opendoor.com/source/s3/imgdrop-production/40cb0b0734474487aab7826d892392a2.png?preset=square-2048');
  }
`;

const data = {
  seller: {
    background: colors.brand50 as Token<ColorScheme>,
    color: colors.neutrals0 as Token<ColorScheme>,
    image:
      'https://images.opendoor.com/source/s3/imgdrop-production/88aa4836e2dd4ed0bbabfe18cbbaff0f.png?preset=square-2048',
  },
  buyer: {
    background: colors.terracotta50 as Token<ColorScheme>,
    color: colors.neutrals100 as Token<ColorScheme>,
    image:
      'https://images.opendoor.com/source/s3/imgdrop-production/cf1dd00bb2f8481481728c5ffb902d64.png?preset=square-2048',
  },
  agent: {
    background: colors.brand50 as Token<ColorScheme>,
    color: colors.neutrals0 as Token<ColorScheme>,
    image:
      'https://images.opendoor.com/source/s3/imgdrop-production/88aa4836e2dd4ed0bbabfe18cbbaff0f.png?preset=square-2048',
  },
};

const renderFooterBox = (entry: IComponentFooterBox) => {
  const { fields } = entry;

  return (
    <Box background={data[fields.variant].background}>
      <Flex
        flexWrap={'wrap'}
        marginX={'auto'}
        alignItems="center"
        justifyContent="space-around"
        maxWidth={['90%', 720, 720, 960]}
        paddingLeft={[null, 24, null, 36]}
      >
        <Box width={['100%', '47.5%', null, null]} paddingY={9}>
          <H2
            {...typography.FooterBoxTitle}
            mb={9}
            fontWeight="semibold"
            color={data[fields.variant].color}
          >
            {fields.title}
          </H2>
          <Text {...typography.body} mb={7} color={data[fields.variant].color}>
            {fields.content && (
              <UL buyer={(fields.variant as string) === 'buyer'}>
                {fields.content.map((e: string, index: number) => (
                  <li key={index + '_' + e}>{e}</li>
                ))}
              </UL>
            )}
          </Text>
          {(fields.variant as string) !== 'buyer' ? (
            <SellerFlowAddressInput
              analyticsName="cosmos-footerbox-address-input"
              channel="DEFAULT"
              inputLocation="footerbox-cta"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={false}
              placeholderText={fields.placeholder}
              showCta
              ctaProps={{
                actionText: fields.buttonText,
                'aria-label': '',
              }}
              product="SELL_DIRECT"
            />
          ) : (
            <Flex gap={[0, 6, 6, 6]}>
              <DownloadButton href="https://play.google.com/store/apps/details?id=com.opendoor.buyerapp&hl=en">
                <img
                  src="https://images.opendoor.com/source/s3/imgdrop-production/3a52f003f27742f3b4384a3168352335.png?preset=square-2048"
                  alt="Play store button"
                  height={45}
                />
              </DownloadButton>
              <DownloadButton href="https://itunes.apple.com/us/app/opendoor-homes-all-day-open/id1143708068?mt=8">
                <img
                  src="https://images.opendoor.com/source/s3/imgdrop-production/03f8e282af254a80ac28358e640319ab.svg?preset=square-2048"
                  alt="App store button"
                  height={45}
                />
              </DownloadButton>
            </Flex>
          )}
        </Box>
        <Flex width={['100%', '47.5%', null, null]} alignSelf={'flex-end'} textAlign={'center'}>
          <img style={{ maxWidth: '100%' }} src={data[fields.variant].image} alt="" />
        </Flex>
      </Flex>
    </Box>
  );
};

const FooterBox: EntryComponent<IComponentFooterBox> = {
  render: renderFooterBox,
};

export default FooterBox;
