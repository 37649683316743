import { Box } from '@opendoor/bricks-next';
import { Entry } from 'contentful';

import Container from 'components/landing-pages-v2/shared/Container';
import {
  getComponentThemeColors,
  IComponentThemeOptions,
} from 'components/landing-pages-v2/shared/styles/ComponentTheme';
import { TitleWithAccent } from 'components/landing-pages-v2/shared/Typography';
import CardCarouselV2 from 'components/shared/CardCarouselV2';
import MulticardCard, { IMulticardCardDetails } from 'components/shared/Multicard/MulticardCard';
import MulticardList from 'components/shared/Multicard/MulticardList';

import { fetchEntriesByTags } from '../../../cms';
import { Awaited, EntryComponent } from '../../../cms/entries/entries';
import {
  IComponentExternalLink,
  IComponentExternalLinkFields,
  IComponentRelatedExternalLinks,
} from '../../../declarations/contentful';
import { getSkipOffset, PAGE_ITEMS_LIMIT } from '../../../helpers/pagination';

const mapExternalLinkToMulticard = (
  data: IComponentExternalLinkFields,
  theme: IComponentThemeOptions,
): IMulticardCardDetails => ({
  eyebrow: data.externalLinkSource?.fields.name,
  title: data.title,
  redirectUrl: data.link,
  componentTheme: theme,
  subhead: data.date
    ? new Date(data.date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : '',
});

const renderExternalLinks = (
  entry: IComponentRelatedExternalLinks,
  resolvedData?: Awaited<ReturnType<typeof externalLinksLoader>>,
) => {
  const externalLinks: Array<IComponentExternalLink> =
    entry?.fields?.items?.slice(0, entry?.fields?.limit) ?? resolvedData?.externalLinks ?? [];
  const componentTheme = getComponentThemeColors('Off White');
  return (
    <Container>
      {entry.fields.title && (
        <Box mb="$10x">
          <TitleWithAccent title={entry.fields.title} titleSize={['48px', null, '64px']} />
        </Box>
      )}
      {entry?.fields?.topicPage?.fields?.slug ? (
        <CardCarouselV2
          cards={externalLinks}
          renderCard={(card, id) => (
            <MulticardCard
              id={id}
              card={mapExternalLinkToMulticard(card.fields, componentTheme)}
              isArched={false}
              componentTheme={componentTheme}
              cardVerticalAlignment="center"
            />
          )}
          isArched={false}
          columnNumber={4}
          componentTheme={componentTheme}
          footerLink={entry?.fields?.topicPage?.fields?.slug}
        />
      ) : (
        <MulticardList
          cards={externalLinks.map((link) =>
            mapExternalLinkToMulticard(link.fields, componentTheme),
          )}
          componentTheme={componentTheme}
          cardVerticalAlignment="center"
          columnNumber={4}
          isArched={false}
        />
      )}
    </Container>
  );
};

interface IExternalLinksLoaderReturn {
  externalLinks: Array<any>;
  total: number | undefined;
}

const externalLinksLoader = async (
  input: IComponentRelatedExternalLinks,
  _root?: Entry<IComponentExternalLink>,
  pageProps = { paginationIndex: 0 },
) => {
  const loaderReturn: IExternalLinksLoaderReturn = {
    externalLinks: [],
    total: 0,
  };
  let externalLinks: Array<IComponentExternalLink | Entry<IComponentExternalLink>> =
    input.fields?.items || [];

  if (externalLinks.length === 0) {
    // Auto selection if no external links were selected
    const tags = input?.metadata?.tags
      ?.filter((item) => item?.sys?.type === 'Link' && item?.sys?.linkType === 'Tag')
      ?.map((item) => item?.sys?.id);
    if (tags?.length > 0) {
      const externalLinksCollection = await fetchEntriesByTags<IComponentExternalLink>(
        'componentExternalLink',
        tags,
        {
          select: 'fields.title,fields.date,fields.link,fields.externalLinkSource',
          skip: getSkipOffset(pageProps.paginationIndex),
          /*
            The field "include" specifies how many levels down of nested
            data that this fetch call should return. Please be aware that any
            value greater than 2 has significant impact on page performance.
          */
          include: 2,
          limit: input.fields?.limit || PAGE_ITEMS_LIMIT,
          order: '-fields.date',
        },
      );
      externalLinks = externalLinksCollection?.items || [];
      loaderReturn.total = externalLinksCollection?.total;
      loaderReturn.externalLinks = externalLinks;
    }
  }

  return loaderReturn;
};

const ExternalLinks: EntryComponent<
  IComponentRelatedExternalLinks,
  Awaited<ReturnType<typeof externalLinksLoader>>
> = {
  render: renderExternalLinks,
  loader: externalLinksLoader,
};

export default ExternalLinks;
