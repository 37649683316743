import { ReactNode } from 'react';

import * as contentful from 'contentful';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Document } from '@contentful/rich-text-types';
import { globalObservability } from '@opendoor/observability/slim';
import { Entry } from 'contentful';

import { DEPLOY_ENV } from '../components/globals';
import {
  IArticle,
  IArticleFields,
  ILandingPageV2,
  ILandingPageV2Fields,
  ISeoBaseFields,
  ISiteFooter,
  ISiteHeader,
  ITopicPageFields,
} from '../declarations/contentful';
import articleEntryLookup from './entries/article';
import landingPageV2EntryLookup from './entries/landingPageV2';
import topicPageEntryLookup from './entries/topicPage';
import { hydrateEntries, IIdToLoaderData } from './loaders';

export type ArticleProps = {
  sys: { createdAt: string; updatedAt: string; tags: Array<string> };
  fields: IArticleFields;
  readingTimeMinutes: number | null;
  idToLoaderData: IIdToLoaderData;
};

export type CollectionProps = {
  total: number;
  items: Array<Entry<any>>;
};

export type TopicPageProps = {
  sys: {
    createdAt: string;
    updatedAt: string;
    locale: string;
  };
  fields: ITopicPageFields;
  idToLoaderData: IIdToLoaderData;
};

export type LandingPageV2Props = {
  children?: ReactNode;
  fields: ILandingPageV2Fields;
  idToLoaderData: IIdToLoaderData;
  markets: [];
  public: boolean;
};

export interface ILandingPageV2LayoutFields {
  /** Slug */
  slug: string;

  /** Header */
  header: ISiteHeader;

  /** Footer */
  footer?: ISiteFooter | undefined;

  /** SEO Base */
  seoBase?: ISeoBaseFields | undefined;

  /** Show Return Experience Banner */
  showReturnExperienceBanner?: boolean | undefined;

  /** Extra Legal Text */
  extraLegalText?: Document | undefined;

  /** Show Eligibility Legal */
  showEligibilityLegal?: boolean | undefined;

  /** Show Customer Testimonial Legal */
  showCustomerTestimonialLegal?: boolean | undefined;

  /** Show the PDP Copyright Legal */
  showThePdpCopyrightLegal?: boolean | undefined;

  /** Show Promotion Legal */
  showPromotionLegal?: boolean | undefined;
}

export type ILandingPageV2LayoutProps<T> = {
  children?: ReactNode;
  fields: T;
  idToLoaderData: IIdToLoaderData;
  hideGoogleOneTap?: boolean;
};

// guards against an unset DEPLOY_ENV variable or a mistakenly
// named DEPLOY_ENV variable (oopsproduction)
// We'll fallback to standard environment if we're not explicitly
// in a non-production environment
// Later this function can take the request context if we want to
// do cookie-based previews
const checkIfPreview = () => {
  return DEPLOY_ENV && ['staging', 'development', 'qa', 'test'].includes(DEPLOY_ENV);
};

const sysFields = ['createdAt', 'updatedAt'];
const metaFields = ['tags'];
const selectedFields: Array<keyof IArticleFields> = [
  'slug',
  'seoBase',
  'authors',
  'showAuthorFooter',
  'articleName',
  'articleSubtitle',
  'header',
  'footer',
  // no relatedArticles
  'headerSummary',
  'headerImage',
  'keyTakeaways',
  'body',
  'editors',
  'reviewers',
  'openGraph',
  'openGraphArticle',
  'twitterCard',
  'publisher',
  'publishDate',
  'hideReadingTime',
];

export const createContentfulClient = () => {
  const isPreview = checkIfPreview();

  let contentfulClientOpts: contentful.CreateClientParams | null = null;

  if (isPreview) {
    contentfulClientOpts = {
      accessToken: process.env.CONTENTFUL_DELIVERY_PREVIEW_ACCESS_TOKEN!,
      host: 'preview.contentful.com',
      space: process.env.CONTENTFUL_SPACE_ID!,
      environment: process.env.CONTENTFUL_ENVIRONMENT!,
    };
  } else {
    contentfulClientOpts = {
      accessToken: process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN!,
      space: process.env.CONTENTFUL_SPACE_ID!,
    };
  }
  return contentful.createClient(contentfulClientOpts);
};

type FetchBySlug = {
  slug: string;
  requestUrl: string;
  pageNum?: number;
};

export async function fetchArticleBySlug({
  slug,
  requestUrl,
}: FetchBySlug): Promise<ArticleProps | null> {
  const client = createContentfulClient();

  // Pretty much the same implementation as more complicated approaches
  // like those in the packages reading-time and worder
  const calculateReadingTime = (input: string) => {
    const WORDS_PER_MINUTE = 200;
    const matches = input.match(/(\w+)/g);
    if (!matches) {
      return null;
    }
    return matches.length / WORDS_PER_MINUTE;
  };

  const entry = await client.getEntries<Omit<IArticleFields, 'relatedArticles'>>({
    content_type: 'article',
    /*
      The field "include" specifies how many levels down of nested
      data that this fetch call should return. Please be aware that any
      value greater than 2 has significant impact on page performance.
    */
    include: 3,
    'fields.slug': slug,
    select: sysFields
      .map((x) => `sys.${x}`)
      .concat(selectedFields.map((x) => `fields.${x}`))
      .concat(metaFields.map((x) => `metadata.${x}`))
      .join(','),
    limit: 1,
  });
  if (entry.items.length === 0) {
    return null;
  }
  const safeEntry = JSON.parse(entry.stringifySafe()) as Omit<
    typeof entry,
    'toPlainObject' | 'stringifySafe'
  >;

  const article = safeEntry.items[0] as IArticle;
  // we can't just fetch relatedArticles, contentful doesn't allow for different "includes"
  // levels in queries, so relatedArticles pulls in megabytes of data
  article.fields.relatedArticles = await getRelatedArticles(article).catch(() => []);
  // calculate the reading time by adding together all of the string fields
  const minutes = calculateReadingTime(
    [
      article.fields.articleName,
      article.fields.headerSummary,
      article.fields.keyTakeaways && documentToPlainTextString(article.fields.keyTakeaways),
      documentToPlainTextString(article.fields.body),
    ]
      .filter(Boolean)
      .join(''),
  );

  let hydratedEntries: Array<[string, any]> = [];
  try {
    hydratedEntries = await Promise.all(
      hydrateEntries({
        includes: safeEntry.includes?.Entry ?? [],
        entryLookup: articleEntryLookup,
        root: article,
        pageContext: {
          url: requestUrl,
        },
      }),
    );
  } catch (e) {
    globalObservability.getSentryClient().captureException?.(e, {
      data: {
        slug,
      },
    });
  }
  const idToLoaderData = Object.fromEntries(hydratedEntries);
  return {
    sys: {
      createdAt: article.sys.createdAt,
      updatedAt: article.sys.updatedAt,
      tags: article.metadata.tags?.map((item) => item?.sys?.id).filter(Boolean) || [],
    },
    fields: article.fields,
    readingTimeMinutes: minutes !== null ? Math.round(minutes) : null,
    idToLoaderData,
  };
}

export async function fetchTopicPageBySlug({
  slug,
  pageNum,
  requestUrl,
}: FetchBySlug): Promise<TopicPageProps | null> {
  const client = createContentfulClient();
  const entries = await client.getEntries<ITopicPageFields>({
    content_type: 'topicPage',
    /*
      The field "include" specifies how many levels down of nested
      data that this fetch call should return. Please be aware that any
      value greater than 2 has significant impact on page performance.
    */
    include: 2,
    'fields.slug': slug,
    limit: 1,
  });
  if (entries.items.length === 0) {
    return null;
  }
  const safeEntries = JSON.parse(entries.stringifySafe()) as Omit<
    typeof entries,
    'toPlainObject' | 'stringifySafe'
  >;
  const topicPage = safeEntries.items[0];
  let hydratedEntries: Array<[string, any]> = [];
  try {
    hydratedEntries = await Promise.all(
      hydrateEntries(
        {
          includes: safeEntries.includes?.Entry ?? [],
          entryLookup: topicPageEntryLookup,
          root: topicPage,
          pageContext: { pageNum, url: requestUrl },
        },
        // transform to new options based
      ),
    );
  } catch (e) {
    globalObservability.getSentryClient().captureException?.(e, {
      data: {
        slug,
      },
    });
  }
  const idToLoaderData = Object.fromEntries(hydratedEntries);

  return {
    sys: {
      createdAt: topicPage.sys.createdAt,
      updatedAt: topicPage.sys.updatedAt,
      locale: topicPage.sys.locale,
    },
    fields: topicPage.fields,
    idToLoaderData,
  };
}

async function getRelatedArticles(article: IArticle): Promise<Array<IArticle>> {
  // grab the article that we want to look up relatedArticles with specifically
  const articleWithRelated = (
    await fetchEntriesById<Pick<IArticleFields, 'relatedArticles'>>('article', [article.sys.id], {
      select: 'fields.relatedArticles',
      include: 0,
    })
  )[0];

  return (
    await fetchEntriesById<Pick<IArticleFields, 'slug' | 'authors' | 'articleName'>>(
      'article',
      (articleWithRelated?.fields?.relatedArticles || []).map((a) => a.sys.id),
      { select: 'fields.slug,fields.authors,fields.articleName' },
    )
  ).filter((article) => !!article) as Array<IArticle>;
}

export async function fetchEntriesByTags<T>(
  contentType: string,
  tags: Array<string> | undefined,
  options: {
    order?: string;
    limit?: number;
    select?: string;
    skip?: number;
    include?: number;
  } = {},
): Promise<CollectionProps | null> {
  if (tags === undefined) {
    return {
      total: 0,
      items: [],
    };
  }
  const { limit, select, skip, order, include = 2 } = options;
  const client = createContentfulClient();
  const response = await client.getEntries<T>({
    content_type: contentType,
    include,
    'metadata.tags.sys.id[in]': tags.join(','),
    ...(order && { order }),
    ...(select && { select }),
    ...(limit && { limit }),
    ...(skip && { skip }),
  });

  if (response.total === 0) {
    return {
      total: 0,
      items: [],
    };
  }
  const safeEntry = JSON.parse(response.stringifySafe()) as Omit<
    typeof response,
    'toPlainObject' | 'stringifySafe'
  >;

  return {
    total: safeEntry.total,
    items: safeEntry.items,
  };
}

export async function fetchArticlesByTags<IArticleLite>(
  tags: Array<string> | undefined,
  options: {
    limit?: number;
    select?: string;
    skip?: number;
  } = {},
): Promise<CollectionProps | null> {
  return fetchEntriesByTags<IArticleLite>('article', tags, {
    ...options,
    order: '-fields.publishDate',
  });
}

export async function fetchEntryById<T>(
  content_type: string,
  id: string,
  options: {
    select?: string;
    include?: number;
  } = {},
) {
  const { select, include } = options;
  const client = createContentfulClient();
  const response = await client.getEntries<T>({
    content_type,
    ...(select && { select }),
    ...(include && { include }),
    'sys.id[in]': `${id}`,
  });
  if (response.items.length === 0) {
    return undefined;
  }
  const safeEntry = JSON.parse(response.stringifySafe()) as Omit<
    typeof response,
    'toPlainObject' | 'stringifySafe'
  >;
  const entry = safeEntry.items[0];
  return entry;
}

export async function fetchEntriesById<T>(
  contentType: string,
  ids: Array<string>,
  options: {
    select?: string;
    include?: number;
  } = {},
) {
  const { select, include } = options;
  const client = createContentfulClient();
  const response = await client.getEntries<T>({
    content_type: contentType,
    ...(select && { select }),
    ...(include && { include }),
    'sys.id[in]': ids.join(','),
  });
  if (response.items.length === 0) {
    return [];
  }
  const safeEntry = JSON.parse(response.stringifySafe()) as Omit<
    typeof response,
    'toPlainObject' | 'stringifySafe'
  >;
  // contentful de-duplicates if you have multiple of the same entry id
  // callers expect the same response shape as the input
  // so if we call with ['id1', 'id2', 'id1']
  // we need to include dupe entries
  return ids.map((id) => safeEntry.items.find((x) => x.sys.id === id)) as Array<
    contentful.Entry<T>
  >;
}

export async function fetchLandingPageV2BySlug({
  slug,
  pageNum,
  requestUrl,
}: FetchBySlug): Promise<LandingPageV2Props | null> {
  const client = createContentfulClient();

  const entry = await client.getEntries<ILandingPageV2Fields>({
    content_type: 'landingPageV2',
    /*
      The field "include" specifies how many levels down of nested
      data that this fetch call should return. Please be aware that any
      value greater than 2 may have a significant impact on page performance,
      so it should be used sparingly and content authors should understand the
      impact of overusing components with several levels of nested data.
    */
    include: 3,
    'fields.slug': slug,
    limit: 1,
  });

  if (entry.items.length === 0) {
    return null;
  }

  const safeEntry = JSON.parse(entry.stringifySafe()) as Omit<
    typeof entry,
    'toPlainObject' | 'stringifySafe'
  >;
  const page = safeEntry.items[0] as ILandingPageV2;

  let hydratedEntries: Array<[string, any]> = [];
  try {
    hydratedEntries = await Promise.all(
      hydrateEntries({
        includes: safeEntry.includes?.Entry ?? [],
        entryLookup: landingPageV2EntryLookup,
        root: page,
        pageContext: {
          url: requestUrl,
          pageNum,
        },
      }),
    );
  } catch (e) {
    globalObservability.getSentryClient().captureException?.(e, {
      data: {
        slug,
      },
    });
  }
  const idToLoaderData = Object.fromEntries(hydratedEntries);
  return {
    fields: page.fields,
    idToLoaderData,
    markets: [], // gets populated by the landing page component
    public: page.fields.public,
  };
}
