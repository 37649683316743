import { Box, Flex, Text } from '@opendoor/bricks/core';
import { shadows } from '@opendoor/bricks/theme/eero';

import { EntryComponent } from '../../cms/entries/entries';
import renderImage from '../../cms/renderer/assets';
import { IImageAsset, IImageAssetFields } from '../../declarations/contentful';

const GifComponent = (fields: IImageAssetFields) => {
  return (
    <Box key={fields.asset.fields.file?.url || 'backup-id'} marginY={5}>
      <Box
        overflow="hidden"
        width={fields.asset.fields.file.details.image?.width}
        height="100%"
        maxWidth="100%"
        position="relative"
      >
        {renderImage({
          src: fields.asset.fields.file?.url,
          description: fields.description,
          width: fields.asset.fields.file.details.image?.width ?? 100,
          height: fields.asset.fields.file.details.image?.height ?? 200,
        })}
      </Box>
    </Box>
  );
};

const IconComponent = (fields: IImageAssetFields) => {
  return (
    <Box display="inline-flex" key={fields.asset.fields.file?.url || 'backup-id'} marginY={5}>
      <Box
        overflow="hidden"
        width={fields.asset.fields.file.details.image?.width}
        height={fields.asset.fields.file.details.image?.height}
        position="relative"
      >
        {renderImage({
          src: fields.asset.fields.file?.url,
          description: fields.description,
          width: fields.asset.fields.file.details.image?.width ?? 100,
          height: fields.asset.fields.file.details.image?.height ?? 200,
        })}
      </Box>
    </Box>
  );
};

const ImageComponent = (fields: IImageAssetFields) => {
  const noTitleAndSource = !fields.title?.trim() && !fields.source?.trim();

  return (
    <Flex width="100%" justifyContent="center" marginY={[6, 6, 8, 8]}>
      <Flex
        bg="neutrals0"
        minWidth={['90%', '85', '80%', '70%']}
        borderRadius="roundedSquare"
        boxShadow={shadows.z2}
        key={fields?.asset?.fields?.file?.url || 'backup-id'}
        flexDirection="column"
        alignItems="center"
      >
        <Box
          borderTopRadius="semiRounded"
          borderBottomRadius={noTitleAndSource ? 'semiRounded' : undefined}
          overflow="hidden"
          width="100%"
        >
          {renderImage({
            src: fields?.asset?.fields?.file?.url,
            description: fields?.description,
            width: fields?.asset?.fields?.file?.details?.image?.width ?? 100,
            height: fields?.asset?.fields?.file?.details?.image?.height ?? 200,
          })}
        </Box>
        {!noTitleAndSource && (
          <Flex flexDirection="column" alignItems="center" p="4">
            {fields.title?.trim() && <Text mb="0">{fields.title}</Text>}
            {fields.source?.trim() && <Text mt="0">Source: {fields.source}</Text>}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const components = {
  gif: GifComponent,
  icon: IconComponent,
  image: ImageComponent,
};

const renderImageAsset = (entry: IImageAsset) => {
  const fields = entry.fields;

  const width = fields?.asset.fields.file.details.image?.width;
  const height = fields?.asset.fields.file.details.image?.height;

  const imageType =
    (fields?.asset.fields.file.contentType === 'image/gif' && 'gif') ||
    (width && height && width <= 128 && height <= 128 && 'icon') ||
    'image';

  const Component = components[imageType];

  return <Component {...fields} />;
};

const ImageAsset: EntryComponent<IImageAsset> = {
  render: renderImageAsset,
};

export default ImageAsset;
