import { AccordionListItem, Flex, List } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronDown from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronDown';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentAccordionList } from '../../declarations/contentful';

const renderAccordionList = (entry: IComponentAccordionList) => {
  const items = (entry.fields.items || []).map(({ fields }) => {
    return {
      title: fields?.title || '',
      children: fields?.body || '',
      // TODO: team to add unique panelId to fields and use this instead of title
      panelId: fields?.title || '',
    };
  });

  return (
    <>
      <SelectiveSpritesheet icons={[ChevronDown]} />
      <Flex>
        <List variant="accordion" analyticsName="cosmos-accordionList" width="100%">
          {items.map((item) => (
            <AccordionListItem variant="secondary" key={item.panelId} wrapText {...item} />
          ))}
        </List>
        {/* <AccordionListUI
          listOfAccordions={items}
          analyticsName="cosmos-accordionList"
          wrapText={true}
        /> */}
      </Flex>
    </>
  );
};

const AccordionList: EntryComponent<IComponentAccordionList> = {
  render: renderAccordionList,
};

export default AccordionList;
