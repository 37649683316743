import { Link } from '@opendoor/bricks/core';

import { EntryComponent } from '../../cms/entries/entries';
import { IComponentImageAssetLink, IImageAsset } from '../../declarations/contentful';
import ImageAsset from './ImageAsset';

const renderImageLink = (entry: IComponentImageAssetLink) => {
  return (
    <Link
      href={entry?.fields?.uri}
      target="_blank"
      analyticsName="cosmos-articles-image"
      aria-label=""
    >
      {ImageAsset.render(entry.fields.image as IImageAsset)}
    </Link>
  );
};

const ImageAssetLink: EntryComponent<IComponentImageAssetLink> = {
  render: renderImageLink,
};

export default ImageAssetLink;
