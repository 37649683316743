import { Document } from '@contentful/rich-text-types';
import { Box, Flex, Link } from '@opendoor/bricks/core';
import { globalObservability } from '@opendoor/observability/slim';
import { Entry } from 'contentful';

import { fetchArticlesByTags, fetchEntriesById } from '../../../cms';
import { Awaited, EntryComponent } from '../../../cms/entries/entries';
import {
  IArticle,
  IArticleFields,
  IAuthorFields,
  IComponentRelatedArticles,
  IEditorFields,
  IReviewerFields,
} from '../../../declarations/contentful';
import { getSkipOffset } from '../../../helpers/pagination';
import ArticleCard from '../ArticleCard';

export const ARTICLES_PER_PAGE = 30;

type IAuthorLite = Pick<
  IAuthorFields,
  'displayName' | 'credential' | 'picture' | 'firstName' | 'lastName'
>;

export type IArticleLite = Pick<
  IArticleFields,
  | 'slug'
  | 'articleName'
  | 'headerSummary'
  | 'authors'
  | 'editors'
  | 'reviewers'
  | 'openGraph'
  | 'publishDate'
>;

const renderArticlesCards = (
  entry: IComponentRelatedArticles,
  resolvedData?: Awaited<ReturnType<typeof articlesLoader>>,
) => {
  const articles: Array<IArticle | Entry<IArticleLite>> =
    entry.fields?.items ?? resolvedData?.articles ?? [];

  if (articles.length === 0) {
    return null;
  }

  const articleCards = articles
    .filter((article) => article && article.fields && article.metadata)
    .map((article, index) => (
      <ArticleCard
        key={index}
        hideImage={entry.fields?.cardFormat === 'No image'}
        imageSourceUrl={article?.fields?.openGraph?.fields?.image}
        slug={article.fields.slug}
        articleName={article.fields.articleName}
        publishDate={article.fields.publishDate}
        tags={article.metadata.tags.map((tag) => tag.sys.id) || []}
        hideCategory={entry?.fields?.hideCategory}
      />
    ));

  let cardsContent = null;
  if (entry?.fields?.layout === 'Spotlight') {
    const remainingArticles = articleCards?.length > 1 ? articleCards.slice(1) : [];
    cardsContent = (
      <Flex width="100%" flexDirection="column" justifyContent="center" alignItems="center">
        <Flex py={[3, 3, 5, 5]} width="100%">
          <ArticleCard
            flexDirection="row"
            hideImage={entry.fields?.cardFormat === 'No image'}
            imageSourceUrl={articles[0]?.fields?.openGraph?.fields?.image}
            slug={articles[0].fields.slug}
            articleName={articles[0].fields.articleName}
            publishDate={articles[0].fields.publishDate}
            tags={articles[0].metadata.tags.map((tag) => tag.sys.id) || []}
            hideCategory={entry?.fields?.hideCategory}
          />
        </Flex>
        {remainingArticles?.length > 0 && (
          <Flex
            py={[3, 3, 5, 5]}
            display="grid"
            gridTemplateColumns={['auto', 'auto auto', 'auto auto auto']}
            gridGap={15}
            gap={[6, 6, 7, 7]}
            width="100%"
          >
            {remainingArticles}
          </Flex>
        )}
      </Flex>
    );
  } else {
    cardsContent = (
      <Box
        py={[3, 3, 5, 5]}
        display="grid"
        gridTemplateColumns={['auto', 'auto auto', 'auto auto auto']}
        gridGap={15}
        gap={[6, 6, 7, 7]}
        width="100%"
      >
        {articleCards}
      </Box>
    );
  }

  return (
    <Flex width="100%" flexDirection="column" justifyContent="center" alignItems="center">
      {cardsContent}
      {entry?.fields?.topicPage?.fields?.slug && (
        <Box my={[6, 6, 7, 7]}>
          <Link
            fontWeight="semibold"
            analyticsName="cosmos-related-external-links-show-more"
            aria-label="cosmos-topicpage-related-external-links-show-more"
            href={entry?.fields?.topicPage?.fields?.slug}
          >
            Show more {'>'}
          </Link>
        </Box>
      )}
    </Flex>
  );
};

// Fetch authors, editors, and reviewers if componentExperts is also in root
const fetchExperts = async (
  articles: (IArticle | Entry<IArticleLite>)[],
  root?: Entry<any>,
): Promise<Array<IAuthorFields | IEditorFields | IReviewerFields>> => {
  // check if componentExperts exists in root
  let isComponentExpertsFound = false;
  for (const content of (root?.fields.body.content ?? []) as Document[]) {
    if (content.data?.target?.sys?.contentType?.sys?.id === 'componentExperts') {
      isComponentExpertsFound = true;
      break;
    }
  }
  if (!isComponentExpertsFound) {
    return [];
  }
  const experts: (IAuthorFields | IEditorFields | IReviewerFields)[] = [];

  // fetch authors
  const authorIds: string[] = [];
  for (const article of articles) {
    for (const author of article.fields.authors ?? []) {
      authorIds.push(author.sys.id);
    }
  }
  const authorEntries = await fetchEntriesById<IAuthorFields>('author', authorIds);
  experts.push(...authorEntries.map((author) => author?.fields ?? null));

  // fetch editors
  const editorIds: string[] = [];
  for (const article of articles) {
    for (const editor of article.fields.editors ?? []) {
      editorIds.push(editor.sys.id);
    }
  }
  const editorEntries = await fetchEntriesById<IEditorFields>('editor', editorIds);
  experts.push(...editorEntries.map((editor) => editor?.fields ?? null));

  // fetch reviewers
  const reviewerIds: string[] = [];
  for (const article of articles) {
    for (const reviewer of article.fields.reviewers ?? []) {
      reviewerIds.push(reviewer.sys.id);
    }
  }
  const reviewerEntries = await fetchEntriesById<IReviewerFields>('reviewer', reviewerIds);
  experts.push(
    ...reviewerEntries.reduce<Array<IReviewerFields>>((acc, reviewer) => {
      if (!reviewer?.fields) {
        return acc;
      }
      return acc.concat(reviewer.fields);
    }, []),
  );

  return experts;
};

export type IArticlesLoaderReturn = {
  authors: Array<IAuthorFields>;
  articles: Array<Entry<IArticleLite>>;
  experts: Array<IAuthorFields | IEditorFields | IReviewerFields>;
  tagNamesLookup: Array<{ [key: string]: string }>;
  total: number | undefined;
};

export const articleLiteFields =
  'fields.slug,fields.articleName,fields.publishDate,metadata.tags,fields.openGraph';
const articleRichFields = 'fields.headerSummary,fields.authors,fields.editors,fields.reviewers';

const articlesLoader = async (
  input: IComponentRelatedArticles,
  root?: Entry<any>,
  pageProps = { paginationIndex: 0 },
) => {
  const loaderReturn: IArticlesLoaderReturn = {
    authors: [],
    articles: [],
    experts: [],
    tagNamesLookup: [],
    total: 0,
  };

  let articles: Array<IArticle | Entry<IArticleLite>> = input.fields?.items || [];

  let tags: string[] = [];
  // Auto selection if no articles is provided
  if (articles.length === 0) {
    tags = input?.metadata?.tags
      ?.filter((item) => item?.sys?.type === 'Link' && item?.sys?.linkType === 'Tag')
      ?.map((item) => item?.sys?.id);
    const articlesCollection = await fetchArticlesByTags(tags || root?.fields.topics, {
      select: `${articleLiteFields},${articleRichFields}`,
      skip: getSkipOffset(pageProps.paginationIndex),
      limit: input.fields?.limit || ARTICLES_PER_PAGE,
    });

    articles = articlesCollection?.items || [];
    const totalArticles = articlesCollection?.total;

    loaderReturn.total = totalArticles;
    loaderReturn.articles = articles;
  }

  // check output for errors
  const missingFields = loaderReturn.articles.some((article) => !article.fields);
  if (missingFields) {
    const sentry = globalObservability.getSentryClient();
    if (sentry !== undefined) {
      sentry.withScope?.((scope) => {
        scope.setContext('Loader Variables', {
          pageProps,
          tags,
        });
        sentry.captureMessage?.('articlesLoader returned undefined articles.fields', 'error');
      });
    }
    // fall back to logging to the console just in case
    // eslint-disable-next-line no-console
    console.log('articlesLoader returned undefined articles.fields', 'error', {
      data: {
        input,
        tags,
        root,
        pageProps,
        articles: loaderReturn.articles,
      },
    });
  }

  // Fetch author of articles
  const authorIds = articles.map((a) => a.fields?.authors?.[0].sys.id ?? '');
  const authorEntries = await fetchEntriesById<IAuthorLite>('author', authorIds, {
    select: 'fields.displayName,fields.credential,fields.picture,fields.firstName,fields.lastName',
  });
  loaderReturn.authors = authorEntries.map((author) => author?.fields ?? null); // JSON serializer cannot accept undefined value

  loaderReturn.experts = await fetchExperts(articles, root);

  return loaderReturn;
};

const RelatedArticles: EntryComponent<
  IComponentRelatedArticles,
  Awaited<ReturnType<typeof articlesLoader>>
> = {
  render: renderArticlesCards,
  loader: articlesLoader,
};

export default RelatedArticles;
