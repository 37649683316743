import { Flex } from '@opendoor/bricks/core';

import articleEntryLookup from '../../cms/entries/article';
import { EntryComponent } from '../../cms/entries/entries';
import RichTextRenderer from '../../cms/renderer';
import renderImage from '../../cms/renderer/assets';
import { IComponentHighlightBox } from '../../declarations/contentful';

const renderHighlightBox = (entry: IComponentHighlightBox) => {
  const { fields } = entry;

  const style = {
    default: {
      background: 'neutrals10',
    },
    leftBorder: {
      background: 'neutrals10',
      borderLeft: '3px solid #ddd',
    },
    dropShadow: {
      background: 'neutrals0',
      boxShadow: 'z2',
    },
  };

  return (
    <Flex
      {...style[fields.variant]}
      marginY={9}
      padding={[7, 10, null, null]}
      flexDirection={'row'}
      flexWrap={'wrap'}
      gap={6}
    >
      {fields.image && (
        <Flex
          width={fields.image.fields.file.details.image?.width ?? 200}
          height={'auto'}
          maxWidth={['100%', '50%', null, null]}
          flexDirection={'column'}
          alignSelf="center"
        >
          {renderImage({
            src: fields.image.fields.file?.url,
            width: fields.image.fields.file.details.image?.width ?? 200,
            height: fields.image.fields.file.details.image?.height ?? 200,
          })}
        </Flex>
      )}
      {fields.content && (
        <Flex alignItems={'flex-start'} flexDirection={'column'} flex={1}>
          <RichTextRenderer.Component
            body={fields.content}
            idToLoaderData={{}}
            entryLookup={articleEntryLookup}
          />
        </Flex>
      )}
    </Flex>
  );
};

const HighlightBox: EntryComponent<IComponentHighlightBox> = {
  render: renderHighlightBox,
};

export default HighlightBox;
