import React, { MouseEvent, useRef } from 'react';

import * as Sentry from '@sentry/nextjs';
import { css } from '@emotion/react';
import { Box, Flex, Image, Link, Text } from '@opendoor/bricks/core';
import type { TypographyProps } from '@opendoor/bricks/system/theme/typography.types';
import { shadows } from '@opendoor/bricks/theme/eero';

import { Awaited, EntryComponent } from '../../cms/entries/entries';
import { IComponentPropertyListings } from '../../declarations/contentful';
import { BUYER_HOST, COSMOS_URL } from '../globals';
import typography from './typography';

export type PropertiesReturn = Awaited<ReturnType<typeof propertyListingsLoader>>;
const detailTypography: TypographyProps = {
  fontSize: ['s0', null, null, 's1'],
  fontWeight: 'regular',
  lineHeight: ['s0', null, null, 's1'],
};

/**
 * Note: this product card attempts to use semantic HTML.
 * The overall structure is:
 * <ul> (entire gallery)
 *   <li> (PropertyCard)
 *     <ul> (attribute list)
 *       <li># Beds
 *       <li># Baths
 *       ...
 *     </ul>
 *     <a><address /></a>
 *   </li>
 *   ...
 * </ul>
 * Each card is a <li> nested under a <ul> when called
 * Attributes are also in a list (with some css to put them inline)
 * The address is in a semantic <address> element
 *
 * The whole card is clickable, but we use an onClick handler for a11y
 * reasons. (only href focuses rather than the whole card focuses)
 *
 */
const PropertyCard: React.FC<PropertyResult> = (props) => {
  const address = `${props.address_line_1}, ${props.city}, ${props.state} ${props.zip}`;

  // Eventually would like to use imgix to do responsive srcSets
  const ref = useRef<HTMLAnchorElement>(null);
  if (!props.photos[0]?.cdn_paths?.['480']) {
    return null;
  }
  // https://images.opendoor.com/source/s3/cabinet-assets/production/storage/a2c50c47-5acb-594f-8ffe-3043a703eb89.jpg
  // /source/s3/cabinet-assets/production/storage/66701bb1-4a14-5c18-9cc8-06ea2f991b84.jpg?service=buyer&token=c0c98583a61dae4735476a51e0c23ccb27aa8b1b7632e4247ad95655b9c64532&w=768
  let srcUrl = null;
  try {
    const parsedSrcUrl = new URL(`https://images.opendoor.com${props.photos[0].cdn_paths['480']}}`);
    // remove params from open_listings and replace with preset params
    parsedSrcUrl.searchParams.delete('service');
    parsedSrcUrl.searchParams.delete('token');
    parsedSrcUrl.searchParams.delete('w');
    parsedSrcUrl.searchParams.set('service', 'cosmos');
    parsedSrcUrl.searchParams.set('preset', '3:2-sm');
    srcUrl = parsedSrcUrl.toString();
  } catch (e) {
    Sentry.captureException(e);
  }

  if (!srcUrl) {
    return null;
  }

  return (
    <Box
      borderRadius="semiRounded"
      cursor="pointer"
      onClick={() => {
        // navigate here, but don't show on screen-reader
        ref?.current?.click();
      }}
      bg="neutrals0"
      width={['75%', '50%', '40%', '30%']}
      minWidth={['75%', '50%', '40%', '30%']}
      height={['unset', null, null, '20%']}
      minHeight={['unset', null, null, '20%']}
      boxShadow={shadows.z1}
      _hover={{
        boxShadow: shadows.z2,
      }}
      as="li"
    >
      <Box borderTopRadius="semiRounded" overflow="hidden" width="100%">
        <Image maxHeight="640px" maxWidth="100%" src={srcUrl} alt="Listing main image" />
      </Box>
      <Box p="4">
        <Text pt="0" pb="0" mt="0" mb="0" {...typography.h4}>
          ${props.current_list_price.toLocaleString()}
        </Text>
        <ul
          style={{ listStyle: 'none', display: 'flex', flexWrap: 'wrap', padding: 0 }}
          // li + li:before acts like "join" and puts a middot in the middle
          css={css`
            li + li::before {
              content: '\00a0\00b7\00a0';
            }
          `}
        >
          <Text as="li" {...detailTypography}>
            {props.bedrooms} br
          </Text>
          <Text as="li" {...detailTypography}>
            {props.bathrooms} ba
          </Text>
          <Text as="li" {...detailTypography}>
            {props.sqft} sqft
          </Text>
        </ul>
        <Link
          ref={ref}
          aria-label={`Go to ${address} detail page`}
          href={`${COSMOS_URL}${props.public_properties_path}`}
          analyticsName="cosmos-articles-pdp-link"
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
          }}
        >
          <Text as="address" {...detailTypography} fontStyle="normal">
            {address}
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

const renderPropertyListings = (
  entry: IComponentPropertyListings,
  resolvedData?: PropertiesReturn,
) => {
  return (
    <Box width={['100%', null, null, '100%']} marginRight={[null, null, null, '-10%']}>
      <ul
        style={{
          listStyle: 'none',
          marginLeft: '0',
          paddingLeft: '0',
          paddingBottom: '25px',
          marginBottom: '10px',
        }}
      >
        <Flex
          position="relative"
          flexWrap={['nowrap', null, null, 'wrap']}
          gridGap={'9'}
          overflowX="auto"
          paddingBottom={'4'}
          paddingLeft={['4', null, null, '0']}
        >
          {(resolvedData?.properties ?? []).map((x) => {
            return <PropertyCard key={x.id} {...x} />;
          })}
        </Flex>
      </ul>
      <Box bg="neutrals0" marginRight="auto" width={'100%'}>
        <Link
          href={`${COSMOS_URL}/homes/${resolvedData?.mapsBreadcrumb}`}
          analyticsName={`cosmos-articles-see-more-${entry.fields.title}`}
          aria-label={`Visit extended ${entry.fields.title}`}
        >
          <Text color="brand50" textDecoration="underline">
            See more {entry.fields.title}
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

type ZoneResult = {
  bounds: Array<[number, number]>;
  center: [number, number];
  breadcrumb_path: string;
};
type PropertyResult = {
  id: string;
  city: string;
  public_properties_path: string;
  state: string;
  zip: string;
  address_line_1: string;
  sqft: number;
  for_sale_at: string;
  published_at: string;
  location: [number, number];
  property_type: string;
  status: string;
  bedrooms: number;
  bathrooms: number;
  current_list_price: number;
  mls_listing_number: string;
  photos: Array<{ cdn_paths: { '480': string } }>;
  neighborhood: string;
  unit_number: string | null;
};

type ListingsReturn = {
  properties: Array<PropertyResult>;
  mapsBreadcrumb: string | null;
};

const propertyListingsLoader = async (
  input: IComponentPropertyListings,
): Promise<ListingsReturn> => {
  if (!input.fields.zoneId) {
    return { properties: [], mapsBreadcrumb: null };
  }
  let res;
  res = await fetch(`${BUYER_HOST}/zones/${input.fields.zoneId}.json`);
  if (res.status !== 200) {
    return { properties: [], mapsBreadcrumb: null };
  }

  const { bounds, breadcrumb_path, center } = (await res.json()) as ZoneResult;

  const searchParams = new URLSearchParams([
    ['page', '1'],
    ['page_size', '9'],
    ['sort', 'newest'],
  ]);
  searchParams.set('bounds[north]', `${bounds[0][0]}`);
  searchParams.set('bounds[south]', `${bounds[1][0]}`);
  searchParams.set('bounds[east]', `${bounds[1][1]}`);
  searchParams.set('bounds[west]', `${bounds[0][1]}`);
  searchParams.set('location[]', `${center[0]}`);
  searchParams.set('location[]', `${center[1]}`);
  res = await fetch(
    `${BUYER_HOST}/zones/${
      input.fields.zoneId
    }/list_properties.json?properties_filter%5Binclude_homebuilder%5D=true&${searchParams.toString()}`,
  );
  if (res.status !== 200) {
    return { properties: [], mapsBreadcrumb: null };
  }

  const propsResult = (await res.json()) as { properties: Array<PropertyResult> };

  return { properties: propsResult.properties, mapsBreadcrumb: breadcrumb_path || null };
};

const PropertyListingsComponent: EntryComponent<IComponentPropertyListings, PropertiesReturn> = {
  render: renderPropertyListings,
  loader: propertyListingsLoader,
};

export default PropertyListingsComponent;
